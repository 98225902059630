import React from 'react';
import DogDisplayPage from './DogDisplayPage';
import lily1 from './assets/images/lily/lily1.jpg';
import lily2 from './assets/images/lily/lily2.jpg';
import lily4 from './assets/images/lily/lily4.jpg';
import lily5 from './assets/images/lily/lily5.jpg';
import lily6 from './assets/images/lily/lily6.jpg';
import lily7 from './assets/images/lily/lily7.jpg';
import lily8 from './assets/images/lily/lily8.jpg';
import lily10 from './assets/images/lily/lily10.jpg';
import lily11 from './assets/images/lily/lily11.jpg';
import lily12 from './assets/images/lily/lily12.jpg';
import lily13 from './assets/images/lily/lily13.jpg';
import lily14 from './assets/images/lily/lily14.jpg';
import lily15 from './assets/images/lily/lily15.jpg';
import lily16 from './assets/images/lily/lily16.jpg';
import lily17 from './assets/images/lily/Lily2018.jpg';

const photos = [
    { src: lily17, width: 210, height: 157 },
    { src: lily1, width: 157, height: 210 },
    { src: lily2, width: 157, height: 210 },
    { src: lily4, width: 210, height: 157 },
    { src: lily5, width: 210, height: 157 },
    { src: lily6, width: 210, height: 157 },
    { src: lily7, width: 157, height: 210 },
    { src: lily8, width: 210, height: 157 },
    { src: lily10, width: 157, height: 210 },
    { src: lily11, width: 157, height: 210 },
    { src: lily12, width: 210, height: 157 },
    { src: lily13, width: 157, height: 210 },
    { src: lily14, width: 210, height: 157 },
    { src: lily15, width: 210, height: 157 },
    { src: lily16, width: 210, height: 157 },
];

const Lily = () => {
    return (
        <DogDisplayPage photos={photos}>
            <h1 className="male">
                <i className="far fa-venus"></i>Lily (retired)
            </h1>
            <table className="dog-chart">
                <tbody>
                    <tr>
                        <td><span className="title">Name</span>GCH A LAGOA LIRIO DA LUNANOVA CGN</td>
                        <td><span className="title">Owner</span>CAROLYNNE ISSA / ANGIE FRASSON</td>
                        <td><span className="title">CKC Registration</span>#EU736745</td>
                        <td><span className="title">PWD</span>#2 PWD BITCH IN 2018 (#5 PWD OVERALL IN 2018)</td>
                    </tr>
                    <tr>
                        <td><span className="title">Sire</span>AM CH / MBIS MBPIS BPISS CAN GCH NORTHSKY DARKHORSE AT LUNANOVA JWD AWD WWD</td>
                        <td><span className="title">Dam</span>LUNANOVA'S GEM OF SILVERSPRING JWD</td>
                    </tr>
                    <tr>
                        <td><span className="title">Coat Type</span>CURLY</td>
                        <td><span className="title">Coat Colour</span>BROWN WITH WHITE MARKINGS</td>
                    </tr>
                    <tr>
                        <td><span className="title">OFA Hips</span>GOOD (ELBOWS NORMAL)</td>
                        <td><span className="title">CERF</span>NORMAL</td>
                        <td><span className="title">OPTIMAL SELECTION PRA</span>normal</td>
                        <td><span className="title">OPTIMAL SELECTION EOPRA</span>normal</td>
                    </tr>
                    <tr>
                        <td><span className="title">OPTIMAL SELECTION IC</span>normal</td>
                        <td><span className="title">OPTIMAL SELECTION GM1</span>NORMAL</td>
                        <td><span className="title">JDCM</span>normal</td>
                        <td><span className="title">MICROPHTHALMIA SYNDROME</span>normal</td>
                    </tr>
                </tbody>
            </table>
        </DogDisplayPage>
    );
}

export default Lily;
