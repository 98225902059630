import React from 'react';
import DogDisplayPage from './DogDisplayPage';
import flower1 from './assets/images/flower/flower1.jpg';
import flower2 from './assets/images/flower/flower2.jpg';
import flower3 from './assets/images/flower/flower3.jpg';
import flower4 from './assets/images/flower/flower4.jpg';
import flower5 from './assets/images/flower/flower5.jpg';
import flower6 from './assets/images/flower/flower6.jpg';
import flower7 from './assets/images/flower/flower7.jpg';
import flower8 from './assets/images/flower/flower8.jpg';
import flower9 from './assets/images/flower/flower9.jpg';
import flower10 from './assets/images/flower/flower10.jpg';
import flower11 from './assets/images/flower/flower11.jpg';
import flower12 from './assets/images/flower/flower12.jpg';
import flower13 from './assets/images/flower/flower13.jpg';
import flower14 from './assets/images/flower/flower14.jpg';
import flower15 from './assets/images/flower/flower15.jpg';
import flower16 from './assets/images/flower/flower16.jpg';
import flower17 from './assets/images/flower/flower17.jpg';
import flower18 from './assets/images/flower/flower18.jpg';
import flower19 from './assets/images/flower/flower19.jpg';
import rainbow from './assets/images/rainbow.svg';

const photos = [
    { src: flower1, width: 210, height: 157 },
    { src: flower2, width: 210, height: 157 },
    { src: flower3, width: 210, height: 157 },
    { src: flower4, width: 210, height: 157 },
    { src: flower5, width: 210, height: 157 },
    { src: flower6, width: 210, height: 157 },
    { src: flower7, width: 210, height: 157 },
    { src: flower8, width: 210, height: 157 },
    { src: flower9, width: 210, height: 157 },
    { src: flower10, width: 210, height: 157 },
    { src: flower11, width: 210, height: 157 },
    { src: flower12, width: 210, height: 157 },
    { src: flower13, width: 210, height: 157 },
    { src: flower14, width: 210, height: 157 },
    { src: flower15, width: 210, height: 157 },
    { src: flower16, width: 210, height: 157 },
    { src: flower17, width: 210, height: 157 },
    { src: flower18, width: 210, height: 157 },
    { src: flower19, width: 157, height: 210 },
];
const Flower = () => {
    return (
        <DogDisplayPage photos={photos}>
            <h1 className="male">
                <span className="rainbow-dog">
                    <i className="far fa-venus"></i>
                    <img src={rainbow} alt="rainbow icon" className="rainbow-icon large" />
                    Flower
                </span>
            </h1>
            <table className="dog-chart">
                <tbody>
                    <tr>
                        <td><span className="title">Name</span>CH LUNANOVA MAIAS FLOR DA LAVANDA AWD CGN</td>
                        <td><span className="title">Owner</span>CAROLYNNE ISSA, LUNANOVA</td>
                        <td><span className="title">CKC Registration</span>#YS421680</td>
                    </tr>
                    <tr>
                        <td><span className="title">Sire</span>CH BIGBAY SELKIE'S WAGGISH MATE</td>
                        <td><span className="title">Dam</span>OLYMPUS GLICERIA MAIA ISSA</td>
                    </tr>
                    <tr>
                        <td><span className="title">Coat Type</span>CURLY</td>
                        <td><span className="title">Coat Colour</span>BLACK WITH WHITE MARKINGS</td>
                    </tr>
                    <tr>
                        <td><span className="title">OFA Hips</span>GOOD</td>
                        <td><span className="title">CERF</span>NORMAL</td>
                        <td><span className="title">Optigen PRA</span>normal</td>
                    </tr>
                    <tr>
                        <td><span className="title">Optigen IC</span>normal</td>
                        <td><span className="title">GM-1</span>normal</td>
                        <td><span className="title">JDCM</span>normal</td>
                    </tr>
                </tbody>
            </table>
        </DogDisplayPage>
    )
}
export default Flower;
