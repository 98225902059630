import React from 'react';
import moon from './assets/images/moon.svg';
import dogs from './assets/images/dogs.jpg';
import nuvet from './assets/images/NuVet.png';
import NuJoint from './assets/images/NuJointPlus1.pdf';
import NuVet from './assets/images/NuVetPlus2.pdf';
import Benefits from './assets/images/supplement-benefits.pdf';

export default class DogsContent extends React.Component {
    render() {
        return (
            <div className="main">
                <div id="lunadogs">
                    <h1>About LunaNova Dogs</h1>
                    <p className="headline">Our goal is to place all our puppies in Forever Homes where people understand the amount of time and commitment it takes to raise a well-behaved, well-socialized dog.</p>
                    <p><img src={dogs} alt="LunaNova dogs" className="left content-image" />LunaNova pups are CKC registered, are implanted with CKC-issued serial coded microchips, are de-wormed, have first vaccines, dew claws removed, and are thouroughly examined and found to be healthy by a veterinarian before going to their forever homes. They are sent home with a food sample, a favourite toy, a familiar-smelling blanket and a 6-week trial of free pet insurance.</p>
                    <p>All puppy families are provided with a package that includes copies of all health clearances, achievements and pedigrees for both sire and dam, general information, and photos/videos of the puppies' first 9 weeks of life at LunaNova. This will include a written comprehensive lifetime guarantee that the dog will not be afflicted with GM-1, PRA, JDCM, EOPRA, MOS, or ImproperCoat; and a 2 year guarantee against hip dysplasia or any other inherited illness or condition.</p>
                    <p>We also provide families with a new puppy reference guide specific to PWDs, and most importantly, we are seriously committed to giving all of our puppy families a lifetime of support.</p>
                    <p>All our Portuguese Water Dogs puppies are placed as pets/companions and on a non-breeding contract with LunaNova Kennels and with the CKC.</p>
                    <p>LunaNova is a member of the <a href="https://www.ckc.ca/en" target="_blank" rel="noopener noreferrer">Canadian Kennel Club (CKC)</a> and <a href="http://pwdcc.org/" target="_blank" rel="noopener noreferrer">The Portuguese Water Dog Club of Canada</a> in good standing. We are also a licenced kennel in the city of Cambridge.</p>
                </div>

                <div className="divider">
                    <img src={moon} alt="LunaNova moon" className="moon" />
                </div>

                <h1 id="about">About Portuguese Water Dogs</h1>

                <div id="origin">
                    <h2>Origin &amp; Purpose of Portuguese Water Dogs</h2>
                    <p>For centuries, this seafaring breed existed everywhere along the coast of Portugal and is considered a purely Portuguese breed. Owing to modifications in the fishing systems used, the breed has become restricted to the province of Algarve which should be considered its home. It was prized by fishermen for its spirited yet obedient nature and a robust, medium build that allowed for a full day's work in and out of the water.</p>
                    <p>The Portuguese Water Dog is an athletic swimmer and diver of exceptional ability and stamina. He aided his master at sea by retrieving gear and broken nets, herding schools of fish and carrying messages between boats and to the shore.</p>
                    <p>For more thorough information on the origin of the Portuguese Water Dog, please refer to the <a href="http://pwdcc.org/" target="_blank" rel="noopener noreferrer">The Portuguese Water Dog Club of Canada</a>.</p>
                </div>
                <div id="appearance">
                    <h2 className="section-header">General Appearance &amp; Temperament</h2>
                    <p>The Portuguese Water Dog is defined by his job. He has a hard, penetrating and attentive expression, splendid sight and a fair nose. Slightly longer than tall, he is a medium-sized, sturdy, athletic swimming dog with webbed feet, short neck, and a wavy or curly coat to protect him from the elements, whether in the water or on land. From his impressive head with piercing gaze through a rugged body to his powerful tail carried up in a ring with its characteristic flowing flag, he presents an indelible impression of strength, spirit and soundness.</p>
                    <blockquote>An animal of spirited disposition he is self-willed, brave and very resistant to fatigue. Exceptionally intelligent and a loyal companion, he does his job with facility and obvious pleasure.
                        <span>From the General Appearance of the Portuguese Water Dog - Canadian Kennel Club - Official Breed Standards, 2010.</span></blockquote>
                </div>

                <div id="health">
                    <h2 className="section-header">Health Issues</h2>
                    <p>People looking for a purebred dog should educate themselves about all aspects of the breed in which they are interested. Portuguese Water Dog breeders believe strongly that good health in dogs can only be maintained by the open discussion of health concerns. Good breeders should be happy to discuss these issues with you. The Portuguese Water Dog is overall a very healthy, robust, family companion. But as in humans, and certainly all breeds of dogs, there are some health problems.</p>

                    <ul className="accordion">
                        <li>
                            <input type="checkbox" defaultChecked />
                            <i />
                            <h3>Eye Problems</h3>
                            <p>PRA: Progressive Retinal Atrophy is a genetically transmitted eye disease found in about 80 breeds of dogs, including Poodles, Akitas, Labrador Retrievers, and the Portuguese Water Dog. PRA causes the blood vessels of the retina to atrophy, or waste away. The end result of retinal atrophy is a gradual but progressive blindness. There is a simple genetic DNA blood test to identify normal non-carriers, carriers and affected dogs. For breeding purposes Normal and Carrier and Affected rated dogs can be bred to A rated non-carriers, and the resulting litters will not produce affected dogs.<br /><br />Dr. Keiko Miyadera, Assistant professor of Ophthalmology at the Optigen University, has identified this "Early Onset PRA" (EOPRA) mutation in research samples supplied through OptiGen's Free DNA Testing/PRA Research program.<br /><br /><small>There is a simple genetic DNA blood test to identify normal non-carriers, carriers and affected dogs. For breeding purposes Normal and Carrier and Affected rated dogs can be bred to A rated non-carriers, and the resulting litters will not produce affected dogs.</small></p>
                        </li>
                        <li>
                            <input type="checkbox" defaultChecked />
                            <i />
                            <h3>Hip Dysplasia</h3>
                            <p>Most breeds have some dogs with hips that are called dysplastic - that is the hip joint is not formed perfectly. The dysplastic dog may have no pain or problems, or it may experience mild to severe discomfort when moving. Treatment, if necessary, can consist of aspirin, anti-inflammatory medication, or surgery in the most severe cases. Breeders should not breed a dog that is diagnosed with hip dysplasia, and should make sire and dam OFA, OVC, and PennHip certificates available to prospective puppy buyers.</p>
                        </li>
                        <li>
                            <input type="checkbox" defaultChecked />
                            <i />
                            <h3>Addison's Disease</h3>
                            <p>Addison's is a rare disorder found in animals and in people. John Kennedy and Jane Austen, among others, lived with the disease. Addison's disease is caused by adrenocortical insufficiency - that is, the adrenal glands stop producing certain hormones that control sugar metabolism and maintain the the salt and water balances in the body. The cause of Addison's in PWD's is unknown at this time. 1 in 200 PWDs are afflicted.</p>
                        </li>
                        <li>
                            <input type="checkbox" defaultChecked />
                            <i />
                            <h3>Puppy Heart Disease</h3>
                            <p>Juvenile dilated cardiomyopathy (JDCM) did not acquire its name in our breed until about 1997. Sometimes, with no warning at all, an otherwise normal pup was simply found dead. There is now a test offered by the University of Pennsylvania for all PWDs. This test will determine whether they believe a dog to be a carrier or not. As long as one parent is clear the litter will not be affected.</p>
                        </li>
                        <li>
                            <input type="checkbox" defaultChecked />
                            <i />
                            <h3>GM-1 Storage Disease</h3>
                            <p>GM-1 Storage Disease is a rare disease that is only found in humans and Portuguese Water Dogs (and English Springer Spaniels). It is a genetically transmitted fatal neurological disorder that is apparent around 5 months of age.The only way affected puppies can be produced is by breeding a Carrier to a Carrier and no responsible breeder would do this.</p>
                        </li>
                        <li>
                            <input type="checkbox" defaultChecked />
                            <i />
                            <h3>Microphthalmia Syndrome (MO)</h3>
                            <p>Microphthalmia and delayed growth syndrome (aka “puppy eye syndrome”) has been found in Portuguese Water Dogs by breeders dating as far back as 1986. Affected dogs present with microphthalmia of varying severity, other eye abnormalities, short stature and other findings. Pedigree studies suggest an autosomal recessive inheritance. Human literature reports numerous syndromes associated with microphthalmia, and many genes have been identified as having a causative role. Fortunately breeders now have a test available to them to determine the genetic status of their dog prior to breeding.</p>
                        </li>
                        <li>
                            <input type="checkbox" defaultChecked />
                            <i />
                            <h3>Chondrodystrophy (CDDY and IVDD) and Chondrodysplasia (CDPA)</h3>
                            <p>Chondrodysplasia is a short-legged phenotype characteristic of many dog breeds. Chondrodystrophy, a separate mutation, also includes a short-legged phenotype as well as premature disc degeneration and increased susceptibility to disc herniation.</p>
                            <p><a href="https://vgl.ucdavis.edu/test/cddy-cdpa" target="_blank" rel="noopener noreferrer">Chondrodystrophy (CDDY and IVDD) and Chondrodysplasia (CDPA) | Veterinary Genetics Laboratory (ucdavis.edu)</a></p>
                            <p><a href="https://www.pawprintgenetics.com/products/tests/details/217/?breed=192" target="_blank" rel="noopener noreferrer">Paw Print Genetics - Chondrodystrophy (CDDY and IVDD Risk) with or without Chondrodysplasia (CDPA) in the Portuguese Water Dog</a></p>
                        </li>
                    </ul>

                    <p>To learn more about the health of Portuguese Water Dogs, visit: <a href="http://pwdcc.org/about-pwds/health/" target="_blank" rel="noopener noreferrer">pwdcc.org/about-pwds/health</a>.</p>

                    <p>No one can predict or guarantee the lifespan of a dog or if it will have a genetic problem, so to ask a breeder to make such a prediction is unrealistic. However, you can depend on us, at LunaNova, to do everything in our power, and use all means available, to breed healthy litters. For additional health information on the Portuguese Water Dog, please refer to the Portuguese Water Dog Club of Canada's website.</p>
                </div>


                <div id="supplements">
                    <h2 className="section-header">NuJoint Hip & Joint Supplement and NuVet Immune Supplement</h2>
                    <p><img src={nuvet} alt="NuJoint and NuVet" className='left content-image' /> At Lunanova, we recognize the benefits of adding a joint supplement and an immune supplement to the dogs' daily routines, for the dogs' whole lives.</p>
                    <p>We use, and highly recommend NuJoint Plus and NuVet Plus!</p>

                    <p><a href={NuJoint} target="_blank" rel="noopener noreferrer">Read more about NuJoint</a></p>

                    <p><a href={NuVet} target="_blank" rel="noopener noreferrer">Read more about NuVet</a></p>

                    <p><a href={Benefits} target="_blank" rel="noopener noreferrer">Read more about Supplement Benefits</a></p>
                </div>

                <div id="standard">
                    <h2 className="section-header">Portuguese Water Dog Breed Standard</h2>
                    <p>For a more comprehensive outline of the breed standard for the Portuguese Water Dog, please refer to <a href="https://www.ckc.ca/CanadianKennelClub/media/Breed-Standards/Group%203/Portuguese-Water-Dog.pdf" target="_blank" rel="noopener noreferrer">this document from the Canadian Kennel Club (CKC)</a>.</p>

                    <div className="divider">
                        <img src={moon} alt="LunaNova moon" className="moon" />
                    </div>
                </div>

                <div id="right">
                    <h1>Is a Portuguese Water Dog right for you?</h1>
                    <div className="split">
                        <div className="area">
                            <p className="headline yes">A PWD may be right for you<br />if you want a dog that</p>
                            <ul>
                                <li>Requires regular and extensive grooming</li>
                                <li>Demands attention and exercise</li>
                                <li>Challenges your will and your sense of humour</li>
                                <li>Thrives on training and human contact</li>
                                <li>Thinks independently</li>
                                <li>Voices its opinion</li>
                                <li>Greets friends and family with unbridled enthusiasm</li>
                            </ul>
                        </div>
                        <div className="area">
                            <p className="headline no">A PWD may <i>not</i> be right for you<br />if you want a dog that</p>
                            <ul>
                                <li>Requires minimal grooming</li>
                                <li>Needs little or no attention or exercise</li>
                                <li>Naps endlessly in front of the fireplace</li>
                                <li>Requires little mental stimulation</li>
                                <li>Presents little or no challenge</li>
                                <li>Blends into the woodwork</li>
                                <li>Is content to be left alone</li>
                            </ul>
                        </div>
                    </div>
                </div>


                <div className="divider">
                    <img src={moon} alt="LunaNova moon" className="moon" />
                </div>

                <div id="cost">
                    <h1>Approximate cost breakdown of owning a Portuguese Water Dog</h1>
                    <p>These costs are current for 2023, based on my Vet's fees, and based on the stores I shop at.  All costs are in Canadian Dollars before Tax.</p>
                    <div className="split">
                        <div className="area">
                            <p className="headline">To Start</p>
                            <table className="cost">
                                <thead>
                                    <tr>
                                        <td><strong>Cost</strong></td>
                                        <td><strong>For</strong></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>$120-180</td>
                                        <td>crate</td>
                                    </tr>
                                    <tr>
                                        <td>$150-500</td>
                                        <td>Toys, treats, leash, tags, beds, grooming supplies, etc. (some people are more extravagant than others!)</td>
                                    </tr>
                                    <tr>
                                        <td>$50-100</td>
                                        <td>City License (varies from city to city)</td>
                                    </tr>
                                    <tr>
                                        <td>$80</td>
                                        <td>Bag of Purina Pro Plan Kibble or $3/lb for Big Country Raw</td>
                                    </tr>
                                    <tr>
                                        <td>$80-120</td>
                                        <td>Each time the dog is groomed. It will need this every 6-10 weeks (depending on how much maintenance you do at home).</td>
                                    </tr>
                                    <tr>
                                        <td>$435</td>
                                        <td>7 weeks of puppy classes</td>
                                    </tr>
                                    <tr>
                                        <td>$140 CDN (after USD$ to CDN$ exchange)</td>
                                        <td>3 month supply of NuJoint and NuVetPlus Supplements.</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>
                                            <p>first year vet costs:</p>
                                            <strong>12 weeks</strong>
                                            <ul className="price-table">
                                                <li>$115 vet consult</li>
                                                <li>$70 vaccines</li>
                                            </ul>
                                            <strong>16 weeks</strong>
                                            <ul className="price-table">
                                                <li>$115 vet consult</li>
                                                <li>$70 vaccines</li>
                                            </ul>
                                            <strong>June-October</strong>
                                            <ul className="price-table">
                                                <li>$380 fecal analysis and heartworm/flea treatment</li>
                                            </ul>
                                            <strong>Spay/Neuter</strong>
                                            <ul className="price-table">
                                                <li>$895 for spay</li>
                                                <li>$650 for neuter</li>
                                                <li>$920 for Cryptorchid neuter</li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="area">
                            <p className="headline">Ongoing</p>
                            <table className="cost">
                                <thead>
                                    <tr>
                                        <td width="120"><strong>Cost</strong></td>
                                        <td><strong>For</strong></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>$200-300</td>
                                        <td>per year for routine vet costs</td>
                                    </tr>
                                    <tr>
                                        <td>$120</td>
                                        <td>per exam for a sick/injured dog, and then whatever the cost to fix the problem (emergency vet clinics are much more expensive)</td>
                                    </tr>
                                    <tr>
                                        <td>$80-140</td>
                                        <td>every two months for grooming (groomers charge more if your dog is matted!)</td>
                                    </tr>
                                    <tr>
                                        <td>$80-120</td>
                                        <td>per month for food ($80 for a bag of Purina Pro Plan kibble or $3/lb for Big Country Raw)</td>
                                    </tr>
                                    <tr>
                                        <td>$380</td>
                                        <td>per year for flea/heartworm</td>
                                    </tr>
                                    <tr>
                                        <td>$45 (Cost after USD$ to CDN$ exchange)</td>
                                        <td>per month for NuJoint and NuVetPlus Supplements.</td>
                                    </tr>
                                    <tr>
                                        <td>additional $</td>
                                        <td>Whatever you choose to spend on toys, treats, and general supplies, and whether you continue training at classes and/or get into any type of obedience, rally, water, show competition</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
