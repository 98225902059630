import React from 'react';
import DogDisplayPage from './DogDisplayPage';
import zelda1 from './assets/images/zelda/zelda.jpg';
import zelda2 from './assets/images/zelda/zelda1.jpg';
import zelda3 from './assets/images/zelda/zelda2.jpg';
import zelda4 from './assets/images/zelda/zelda3.jpg';
import zelda5 from './assets/images/zelda/zelda4.jpg';
import zelda6 from './assets/images/zelda/zelda5.jpg';
import zelda7 from './assets/images/zelda/zelda6.jpg';
import zelda8 from './assets/images/zelda/zelda7.jpg';
import zelda9 from './assets/images/zelda/zelda8.jpg';
import zelda10 from './assets/images/zelda/zelda9.jpg';
import zelda11 from './assets/images/zelda/zelda10.jpg';

const photos = [
    { src: zelda1, width: 210, height: 210 },
    { src: zelda2, width: 210, height: 157 },
    { src: zelda3, width: 210, height: 157 },
    { src: zelda4, width: 157, height: 210 },
    { src: zelda5, width: 157, height: 210 },
    { src: zelda6, width: 157, height: 210 },
    { src: zelda7, width: 157, height: 210 },
    { src: zelda8, width: 157, height: 210 },
    { src: zelda9, width: 157, height: 210 },
    { src: zelda10, width: 157, height: 210 },
    { src: zelda11, width: 157, height: 210 }
];

const Zelda = () => {
    return (
        <DogDisplayPage photos={photos}>
            <h1 className="male">
                <i className="far fa-venus"></i>Zelda (retired)
            </h1>
            <table className="dog-chart">
                <tbody>
                    <tr>
                        <td><span className="title">Name</span>CH LUNANOVA'S LEGEND OF ZELDA JWD AWD</td>
                        <td><span className="title">Owner</span>CAROLYNNE ISSA / ANTHONY ISSA</td>
                        <td><span className="title">CKC Registration</span>#DS672967</td>
                    </tr>
                    <tr>
                        <td><span className="title">Sire</span>AM CH COSMOS LET'S CELEBRATE CWDX</td>
                        <td><span className="title">Dam</span>CH LUNANOVA'S BE MY SILVERSPRING JWD AWD RN</td>
                    </tr>
                    <tr>
                        <td><span className="title">Coat Type</span>CURLY</td>
                        <td><span className="title">Coat Colour</span>BROWN WITH WHITE MARKINGS</td>
                    </tr>
                    <tr>
                        <td><span className="title">OFA Hips</span>GOOD</td>
                        <td><span className="title">OFA Elbows</span>NORMAL</td>
                        <td><span className="title">CERF</span>NORMAL</td>
                        <td><span className="title">Optigen PRA</span>normal</td>
                        <td><span className="title">Optigen EOPRA</span>normal</td>
                    </tr>
                    <tr>
                        <td><span className="title">Optigen IC</span>normal</td>
                        <td><span className="title">GM-1</span>normal</td>
                        <td><span className="title">JDCM</span>normal</td>
                        <td><span className="title">MICROPHTHALMIA SYNDROME</span>normal</td>
                    </tr>
                </tbody>
            </table>
        </DogDisplayPage>
    )
}
export default Zelda;
