import React from 'react';
import Iframe from 'react-iframe';

class Contact extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="contact-pg-container">
                <div className="contact content">
                    {/* <div className="main">
                        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdewvebimSlPXUtE5O2GzxgW88k-SmUPanKIU-QeUYJlyojag/viewform?embedded=true" height="900" frameBorder="0" marginHeight="0" marginWidth="0" title="LunaNova Contact Form">Loading…</iframe>
                    </div> */}
                    <Iframe url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d110318.80321367769!2d-80.41438764972631!3d43.39878756540424!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b89b820e46c19%3A0x5037b28c7231d70!2sCambridge%2C+ON!5e0!3m2!1sen!2sca!4v1555078002596!5m2!1sen!2sca"
                        width="1000px"
                        height="350px"
                        id="lunaMap"
                        display="initial"
                        position="relative"
                        allowFullScreen />
                    <div className="side">
                        <h2>Our Location</h2>
                        <ul>
                            <li><b>Phone:</b>519-590-4619</li>
                            <li><b>Email:</b>lunanovapwds@gmail.com</li>
                        </ul>
                        <a href="https://forms.gle/WwFxTU3bxb6yc65k6" target="_blank" rel="noopener noreferrer" className="call-to-action small">
                            <span>Apply to Adopt</span>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contact;
