import React from 'react';
import santa from './assets/images/santa-pic-2013.jpg';
import lounge from './assets/images/dogs-lounging-in-tv-room.jpg';
import dahlia from './assets/images/dahlia.jpg';
import kitchen from './assets/images/dogs-in-kitchen.jpg';
import moon from './assets/images/moon.svg';
import reunion from './assets/images/2019-lunanova-reunion.jpg';
import joanne1 from './assets/images/joanne1.jpg';
import joanne2 from './assets/images/joanne2.jpg';
import joanne3 from './assets/images/joanne3.jpg';
import joanne4 from './assets/images/joanne4.jpg';
import volhard from './assets/images/Volhard-info.pdf';
class About extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="about-container">
                <div className="about content">
                    <div className="main">
                        <h1>About LunaNova</h1>
                        <h2>A letter from Carolynne</h2>
                        <p>
                            My first Portuguese Water Dog, Maia (born in 2008), was chosen for one main reason. I felt like she was the best puppy to bring into my children’s lives. The breeder I was dealing with at the time had several pups for me to choose from. She encouraged me to choose one of the many gorgeous <img src={santa} alt="family pic with Santa" className="right content-image" /> black puppies. But, I could not overlook Maia’s beautiful temperament, despite the fact she was mostly white, and perhaps not as “show-worthy” as some of her littermates. At this time, my priority was not having a “show dog”.<br /><br />
                            As they say “the rest is history”. Maia mothered my children (my youngest was just 1 year old when Maia came into our lives). She has been such a gentle soul, and the generations of offspring she has produced have all been blessed with inheriting beautiful souls just like her. Of course, I have to give credit to the stud dogs I have chosen, as I always choose the most gentle and calm stud dogs available to me. Temperament and health are my main focus when it comes to breeding.<br /><br />
                            In 2014, my sister Joanne Skalos, who had always helped me with the puppies from the very first litter, decided to acquire her first PWD, a stunning girl named Scout, our first Silverspring dog! At that point we became true partners and co- breeders. All of the puppies are born and raised at my house, and Joanne stays here while we are rearing the pups. Between her and I, and my (now much older sons), the puppies get 24/7 supervision and attention.<br /><br />
                            I am very proud and fortunate to say that almost all the families who have a Lunanova dog stay in touch...some just once in a while, others a few times a week, and some daily! This is not just “dumb luck” but a testament to the stability of the temperaments my dogs produce, combined with my process of being selective in the families I choose.<br /><br />
                            Although “kennel” is the correct term from a breeder perspective, I do not really feel we are a kennel. There are no outdoor housings for dogs here. Our dogs are all beloved pets who live with us in our house. The young dogs are only crated when no one is home, and the mature dogs have free run of our home. They sleep on the sofas, and cuddle with the kids while they watch television. At supper time there is always a gang of dogs laying at the kids’ feet, hoping for an accidental dropping of food! Although we have a great big yard, the dogs really just prefer being with their human family.<br /><br />
                            My passion for every single puppy starts very early in their life… before birth… before conception even… worrying about every little detail, studying pedigrees, always learning ... in the hopes that the new litters will be as amazing as the dogs I have produced in the past. I have become fascinated with genetics over the years, and having a basic understanding of genetics, helps me to keep improving.<br /><br />
                            Being a breeder is ninety percent joy! Puppy breath sweet kisses, laughter, fun, and love. It is truly so wonderful and fulfilling.<br /><br />
                            At times though, it comes with heartache. A stillborn puppy is absolutely heart-wrenching, almost enough to make me never want to breed again. Or one time I had a puppy born with a cleft palate, and the vet said to euthanize but I couldn’t bring myself to do it,<img src={lounge} alt="lounging in tv room" className="left content-image" /> so I tube fed her every 2 hours for days, only to lose her in the end anyway. Or even just watching my youngest son (and sometimes my sister) cry when the puppies leave for their forever homes.<br /><br />
                            I love each pup as if I had given birth to it myself (something our own mother often teases me about). And many times I feel guilt, because the beings I actually DID give birth to often have to hear “I cannot deal with you right now; I need to concentrate on the puppies”.<br /><br />
                            During these emotional times, it’s hard not to question, “why do I do this?”<br /><br />
                            But the answer is so simple. The primary reason I breed is because I want other families to have the kind of joy in their lives that my PWDs bring to my and my family’s lives.I really truly believe they are a special breed!! Luckily, I am blessed with family who share the same<img src={dahlia} alt="dahlia" className="right content-image" /> passion for the dogs as I do! So please don’t feel sad when I have tears in my eyes and my kids cry the day you take your new puppy home, as this only shows how much the pup is loved. The kids get attached to the pups… how could they not? I believe that the constant interaction with the kids, and adult dogs in our busy house predestines the puppies to be tolerant and well rounded.<br /><br />
                            I am very fortunate that for most litters, the adoptive families are already committed to adopting a pup before they are even born, and often before they are even conceived. It’s such a wonderful feeling to bring life into the world knowing that the life is SO wanted. Of course, there is also the guilt of knowing I am bringing life into the world, while unwanted dogs sit in shelters, which is why I make a donation to my local shelter from the proceeds of every litter. As much as I admire families who adopt homeless dogs, if breeders stopped breeding purebred dogs because of the guilt of knowing there are homeless dogs needing homes, many very special breeds including the PWD would be lost.<br /><br />
                            The commitment I make to my pups and their families spans from birth, then the day they leave my house as a baby, right until they make their journey to the rainbow bridge. I honestly believe that I am ultimately responsible for every life I produce.<img src={kitchen} alt="dogs in the kitchen" className="left content-image" />I welcome and encourage open lines of communication between myself and the people who own a dog from LunaNova. I love getting updates, questions, phonecalls, texts and photos from people. Over the years, some of these breeder/buyer relationships have actually morphed into real friendships!<br /><br />
                            I am proud to say that I consider myself an avid “conformation breeder” This is important in the sense that it means the dogs are deemed to be a good representation/specimen of the breed standard. This does not mean that most families who get a pup from me want a “show dog”. It just means that the pup they get is down-line from generations of Champion Show Dogs.<br /><br />
                            Perry, our stud dog, took us on the ride of a lifetime, finishing #1 PWD in Canada for 2016, and taking us to every breeder’s dream, Westminister Show in NYC in 2017! And Lily, at not even two years old finished the 2018 year as #2 female in Canada! All of my dogs have been shown, and most have at least a Canadian CH.<br /><br />
                            I will admit though, that dog shows are not where my passion lies. I am much more proud of the obedience and water titles me and my dogs have earned, as this really showcases the drive and intelligence these dogs possess. Plus it’s just so much fun, and so rewarding!<br /><br />
                            I am also very proud of my volunteer work for the Portuguese Water Dog Club Of Canada. This wonderful organization’s main focus is to protect and better the breed, and also runs all club-hosted events in Canada.<br /><br />
                            I served as Secretary for the Ontario Branch of the PWDCC from 2017-2022, and I am currently The Chair of our Annual Water Trial Committee in Ontario, a position I plan to keep as long as they want me! The Water Trials are my true passion/favourite sport for PWDs.<br /><br />
                            Everyday I wake up and know that my dogs are a gift. They make the bad days good, and the good days better! There are not enough words to describe the love they give…<br /><br />
                            I host an annual reunion/picnic/potluck every Fall, and we always have a wonderful turnout! I just cherish this time to spend a fun day with all of the Lunanova dogs, and their wonderful humans!<br /><br />
                            <div className="image-caption">
                                <img src={reunion} alt="2019 LunaNova reunion" className="content-image full-width" />
                                <small>2019 Reunion</small>
                            </div>
                            Lastly, I can only take a portion of the credit for the amazing Lunanova dogs out in the world!! The majority of the credit has to be given to the amazing people who make the commitment to time and training, exercising, socializing, providing structure and conditioning… and mostly … loving their dogs as much as I love mine!<br /><br />

                            <b>Carolynne Issa</b>
                        </p>
                        <div className="divider">
                            <img src={moon} alt="LunaNova moon" className="moon" />
                        </div>
                        <h2>A letter from Joanne</h2>
                        <p><img src={joanne1} alt="group shot of dogs" className="right content-image" />The first time I ever heard about Portuguese Water Dogs was when my parents came back from a trip to the Algarve's, raving about watching these dogs waiting on docks for their owners to return with the boats. A few years later, I met a Portuguese Water Dog while on vacation and was immediately smitten! When my sister Carolynne was researching breeds, I joined in the search and was with her and the boys when they chose Maia.<br /><br />
                            When Maia had her first litter, I did a u-turn on the way to work so I could be there. Watching Maia have puppies was an amazing experience for me and I spent most nights in the whelping box watching with amazement puppy development.<br /><br />
                            When my family decided the time was right to get our first dog, we of course chose a Golden Retriever, lol! Payton was the right dog for us at the time, but the HAIR! When we decided Payton needed a companion, we knew it had to be a Portuguese Water Dog.<br /><br />
                            <img src={joanne2} alt="group shot of dogs" className="left content-image" />A year later, I approached Carolynne asking if I could add one of her wonderful dogs to my family and join her in her breeding program. She became my mentor, and in turn we became partners and co-owners when I took home a puppy from Maia's last litter. Scout was a list of firsts for me as I entered the dog show world with her (as a spectator of course!); her very first weekend showing she won Best of Breed and while I had no idea what it meant, I knew it was special! I also became interested in water work and obedience titles and achieved her Canine Good Neighbor Award when she was 6 months old as well as obtaining her Junior Water Dog Certificate and getting a Rally Novice Title on her.<br /><br />
                            I actively got involved with the club in 2017 and served as Chair of the Ontario Branch of the Portuguese Water Dog Club of Canada from 2017-2022, as well as serving my first year as the Water Trial Secretary in 2019 (PWDCC Ontario Branch Water Trials), a position I continue to hold.<br /><br />
                            Through the dogs, I have met so many interesting people - our new owners,<img src={joanne3} alt="group shot of dogs" className="right content-image" />other breeders, handlers, and water enthusiasts.<img src={joanne4} alt="group shot of dogs" className="left content-image" /></p>

                        <p><b>Joanne</b></p>
                    </div>
                    <div className="side">
                        <h2>Links</h2>
                        <ul>
                            <li><a href="http://pwdcc.org/PWDCC/index.php/health" target="_blank" rel="noopener noreferrer">Portuguese Water Dog Health Information</a></li>
                            <li><a href={volhard} target="_blank" rel="noopener noreferrer">Volhard’s Puppy Aptitude Testing</a></li>
                            <li><a href="https://www.facebook.com/groups/1533131430251311/" target="_blank" rel="noopener noreferrer">LunaNova on Facebook</a></li>
                            <li><a href="https://showsightmagazine.com/neither-of-my-dogs-killed-a-shelter-dog-3/" target="_blank" rel="noopener noreferrer">Neither of my dogs killed a shelter dog</a></li>
                            <li><a href="http://www.4mypwds.com/" target="_blank" rel="noopener noreferrer">4 My PWDs</a></li>
                            <li><a href="https://pwdcc.org/wp-content/uploads/PWDCC-WT-Manual-Mar-13.pdf" target="_blank" rel="noopener noreferrer">2024 PWDCC Water Trial Manual</a></li>
                            <li><a href="https://s3.amazonaws.com/amo_hub_content/Association1696/files/Activities%20or%20Events/Water/Water%20Trial%20Manual%202022.pdf" target="_blank" rel="noopener noreferrer">PWDCA Water Trial Manual</a></li>
                        </ul>
                        {/* <h2>More</h2> */}
                        {/* <a href="https://lunanovaportuguesewaterdogs.com/images/PWD-REAL-standard-joke.pdf" target="_blank" rel="noopener noreferrer" download="PWD-Real-Standard-Joke">The REAL PWD standard (joke)</a> */}
                    </div>
                </div>
            </div>
        );
    }
}

export default About;
