import React from 'react';
import DogDisplayPage from './DogDisplayPage';
import mason1 from './assets/images/mason/mason1.jpg';
import mason2 from './assets/images/mason/mason2.jpg';
import mason3 from './assets/images/mason/mason3.jpg';
import mason4 from './assets/images/mason/mason4.JPG';
import mason5 from './assets/images/mason/mason5.JPG';
import mason6 from './assets/images/mason/mason6.JPG';
import mason7 from './assets/images/mason/mason7.JPG';
import mason8 from './assets/images/mason/mason8.JPG';
import mason9 from './assets/images/mason/mason9.JPG';
import mason10 from './assets/images/mason/mason10.JPG';

const photos = [
    { src: mason1, width: 210, height: 157 },
    { src: mason2, width: 210, height: 157 },
    { src: mason3, width: 210, height: 157 },
    { src: mason4, width: 157, height: 210 },
    { src: mason5, width: 210, height: 157 },
    { src: mason6, width: 210, height: 157 },
    { src: mason7, width: 157, height: 210 },
    { src: mason8, width: 210, height: 157 },
    { src: mason9, width: 157, height: 210 },
    { src: mason10, width: 210, height: 157 },
];

const Mason = () => {
    return (
        <DogDisplayPage photos={photos}>
            <h1 className="male">
                <i className="far fa-mars"></i>Mason (retired)
            </h1>
            <table className="dog-chart">
                <tbody>
                    <tr>
                        <td><span className="title">Name</span>LUNANOVA'S MASON WITH ATTITUDE</td>
                        <td><span className="title">Owner</span>CAROLYNNE ISSA / PAUL BAXANDALE</td>
                        <td><span className="title">CKC Registration</span>#EG708445</td>
                    </tr>
                    <tr>
                        <td><span className="title">Sire</span>AM CH COSMOS LET'S CELEBRATE CWDX</td>
                        <td><span className="title">Dam</span>CH LUNANOVA'S BE MY SILVERSPRING JWD AWD RN</td>
                    </tr>
                    <tr>
                        <td><span className="title">Coat Type</span>WAVY</td>
                        <td><span className="title">Coat Colour</span>BLACK AND WHITE </td>
                    </tr>
                    <tr>
                        <td><span className="title">OFA Hips</span>FAIR (ELBOWS NORMAL)</td>
                        <td><span className="title">CERF</span>NORMAL</td>
                        <td><span className="title">GM-1</span>normal</td>
                        <td><span className="title">JDCM</span>normal</td>
                    </tr>
                    <tr>
                        <td><span className="title">Optigen PRA</span>normal</td>
                        <td><span className="title">OPTIGEN EOPRA</span>NORMAL</td>
                        <td><span className="title">Optigen IC</span>normal</td>
                        <td><span className="title">MICROPHTHALMIA SYNDROME</span>normal</td>
                    </tr>
                </tbody>
            </table>
        </DogDisplayPage>
    );
}
export default Mason;
