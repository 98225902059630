import React from 'react';
import DogDisplayPage from './DogDisplayPage';
import gibson1 from './assets/images/gibson/gibson1.jpg';
import gibson2 from './assets/images/gibson/gibson2.jpg';
import gibson3 from './assets/images/gibson/gibson3.jpg';
import gibson4 from './assets/images/gibson/gibson4.jpg';
import gibson5 from './assets/images/gibson/gibson5.jpg';
import gibson6 from './assets/images/gibson/gibson6.jpg';
import gibson7 from './assets/images/gibson/gibson7.jpg';
import gibson8 from './assets/images/gibson/gibson8.jpg';
import gibson9 from './assets/images/gibson/gibson9.jpg';
import gibson10 from './assets/images/gibson/gibson10.jpg';
import gibson11 from './assets/images/gibson/gibson11.jpg';
import gibson12 from './assets/images/gibson/gibson12.jpg';
import gibson13 from './assets/images/gibson/gibson13.jpg';
import gibson14 from './assets/images/gibson/gibson14.jpg';
import gibson15 from './assets/images/gibson/gibson15.jpg';
import gibson16 from './assets/images/gibson/gibson16.jpeg';
import gibson17 from './assets/images/gibson/gibson17.jpeg';
import gibson18 from './assets/images/gibson/gibson18.jpg';
import gibson19 from './assets/images/gibson/gibson19.jpg';
import gibson20 from './assets/images/gibson/gibson20.jpg';
import gibson21 from './assets/images/gibson/gibson21.jpg';

const photos = [
    { src: gibson1, width: 210, height: 157 },
    { src: gibson2, width: 210, height: 210 },
    { src: gibson3, width: 157, height: 210 },
    { src: gibson4, width: 210, height: 157 },
    { src: gibson5, width: 157, height: 210 },
    { src: gibson6, width: 157, height: 210 },
    { src: gibson7, width: 157, height: 210 },
    { src: gibson8, width: 157, height: 210 },
    { src: gibson9, width: 157, height: 210 },
    { src: gibson10, width: 210, height: 157 },
    { src: gibson11, width: 157, height: 210 },
    { src: gibson12, width: 157, height: 210 },
    { src: gibson13, width: 210, height: 157 },
    { src: gibson14, width: 157, height: 210 },
    { src: gibson15, width: 157, height: 210 },
    { src: gibson16, width: 157, height: 210 },
    { src: gibson17, width: 157, height: 210 },
    { src: gibson18, width: 157, height: 210 },
    { src: gibson19, width: 210, height: 157 },
    { src: gibson20, width: 210, height: 157 },
    { src: gibson21, width: 210, height: 157 },
];

const Gibson = () => {
    return (
        <DogDisplayPage photos={photos}>
            <h1 className="female">
                <i className="far fa-venus"></i>Gibson
            </h1>
            <table className="dog-chart">
                <tbody>
                    <tr>
                        <td><span className="title">Name</span>GCH LUNANOVA'S BREWMASTER MELODY MAKER</td>
                        <td><span className="title">Owner</span>Carolynne Issa<br />ANGIE FRASSON</td>
                        <td><span className="title">CKC Registration</span>#HU4035931</td>
                    </tr>
                    <tr>
                        <td><span className="title">Sire</span>CH DREWSAR WNDRFF THE BREW MASTER</td>
                        <td><span className="title">Dam</span>GCH A LAGOA LIRIO DA LUNANOVA CGN</td>
                    </tr>
                    <tr>
                        <td><span className="title">Coat Type</span>WAVY</td>
                        <td><span className="title">Coat Colour</span>BLACK WITH WHITE MARKINGS</td>
                    </tr>
                    <tr>
                        <td><span className="title">CERF</span>normal</td>
                        <td><span className="title">CDDY/IVVD</span>normal</td>
                        <td><span className="title">CDPA</span>normal</td>
                        <td><span className="title">EOPRA</span>normal</td>
                    </tr>
                    <tr>
                        <td><span className="title">GM-1</span>normal</td>
                        <td><span className="title">Improper Coat</span>normal</td>
                        <td><span className="title">JDCM</span>normal</td>
                        <td><span className="title">MICROPHTHALMIA SYNDROME</span>normal</td>
                    </tr>
                    <tr>
                        <td><span className="title">OFA Hips</span>GOOD (ELBOWS NORMAL)</td>
                        <td><span className="title">PRA</span>normal</td>
                    </tr>
                </tbody>
            </table>
        </DogDisplayPage>
    );
}

export default Gibson;
