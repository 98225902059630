import React from 'react';
import DogDisplayPage from './DogDisplayPage';
import maggie1 from './assets/images/maggie/maggie1.jpg';
import maggie2 from './assets/images/maggie/maggie2.jpg';
import maggie3 from './assets/images/maggie/maggie3.jpg';
import maggie4 from './assets/images/maggie/maggie4.jpg';
import maggie5 from './assets/images/maggie/maggie5.jpg';
import maggie6 from './assets/images/maggie/maggie6.jpg';
import maggie7 from './assets/images/maggie/maggie7.jpg';
import maggie8 from './assets/images/maggie/maggie8.jpg';
import maggie9 from './assets/images/maggie/maggie9.jpg';
import maggie10 from './assets/images/maggie/maggie10.jpg';
import maggie11 from './assets/images/maggie/maggie11.jpg';
import maggie12 from './assets/images/maggie/maggie12.jpg';
import maggie13 from './assets/images/maggie/maggie13.jpg';
import maggie14 from './assets/images/maggie/maggie14.jpg';
import maggie15 from './assets/images/maggie/maggie15.jpg';
import maggie16 from './assets/images/maggie/maggie16.jpg';
import maggie17 from './assets/images/maggie/maggie17.jpg';
import maggie18 from './assets/images/maggie/maggie18.jpg';
import maggie19 from './assets/images/maggie/maggie19.jpg';
import maggie20 from './assets/images/maggie/maggie20.jpg';
import maggie21 from './assets/images/maggie/maggie21.jpg';

const photos = [
    { src: maggie1, width: 165, height: 210 },
    { src: maggie2, width: 165, height: 210 },
    { src: maggie3, width: 157, height: 210 },
    { src: maggie4, width: 157, height: 210 },
    { src: maggie5, width: 157, height: 210 },
    { src: maggie6, width: 157, height: 210 },
    { src: maggie7, width: 157, height: 210 },
    { src: maggie8, width: 157, height: 210 },
    { src: maggie9, width: 157, height: 210 },
    { src: maggie10, width: 210, height: 210 },
    { src: maggie11, width: 210, height: 157 },
    { src: maggie12, width: 157, height: 210 },
    { src: maggie13, width: 157, height: 210 },
    { src: maggie14, width: 157, height: 210 },
    { src: maggie15, width: 210, height: 210 },
    { src: maggie16, width: 157, height: 210 },
    { src: maggie17, width: 157, height: 210 },
    { src: maggie18, width: 1, height: 2 },
    { src: maggie19, width: 157, height: 210 },
    { src: maggie20, width: 157, height: 210 },
    { src: maggie21, width: 157, height: 210 },
];

const Maggie = () => {
    return (
        <DogDisplayPage photos={photos}>
            <h1 className="female">
                <i className="far fa-venus"></i>Maggie
            </h1>
            <table className="dog-chart">
                <tbody>
                    <tr>
                        <td><span className="title">Name</span>LUNANOVA'S STARR MAGNOLIA</td>
                        <td><span className="title">Owner</span>CAROLYNNE ISSA / JOANNE SKALOS</td>
                        <td width="100px"><span className="title">CKC Registration</span>#JE4073670</td>
                    </tr>
                    <tr>
                        <td><span className="title">Sire</span>CH LUNANOVA'S CHILKO FREESTONE</td>
                        <td><span className="title">Dam</span>CH SKIPNSTONES GODDESS AT LUNANOVA jwd</td>
                    </tr>
                    <tr>
                        <td><span className="title">Coat Type</span>WAVY</td>
                        <td><span className="title">Coat Colour</span>BLACK WITH WHITE MARKINGS</td>
                    </tr>
                    <tr>
                        <td><span className="title">CERF</span>NORMAL</td>
                        <td><span className="title">CDDY/IVVD</span>normal</td>
                        <td><span className="title">CDPA</span>normal</td>
                        <td><span className="title">EOPRA</span>normal</td>
                    </tr>
                    <tr>
                        <td><span className="title">GM-1</span>normal</td>
                        <td><span className="title">IMPROPER COAT</span>normal</td>
                        <td><span className="title">JDCM</span>normal</td>
                        <td><span className="title">MICROPHTHALMIA SYNDROME</span>normal</td>
                    </tr>
                    <tr>
                        <td><span className="title">OFA Hips</span>EXCELLENT (ELBOWS NORMAL)</td>
                        <td><span className="title">PRA</span>normal</td>
                    </tr>
                </tbody>
            </table>
        </DogDisplayPage>
    )

}
export default Maggie;
