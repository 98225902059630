import React from 'react';
import DogDisplayPage from './DogDisplayPage';
import freya1 from './assets/images/freya/freya1.jpg';
import freya2 from './assets/images/freya/freya2.jpg';
import freya3 from './assets/images/freya/freya3.jpg';
import freya4 from './assets/images/freya/freya4.jpg';
import freya5 from './assets/images/freya/freya5.jpg';
import freya6 from './assets/images/freya/freya6.jpg';
import freya7 from './assets/images/freya/freya7.jpg';
import freya8 from './assets/images/freya/freya8.jpg';
import freya9 from './assets/images/freya/freya9.jpg';
import freya10 from './assets/images/freya/freya10.jpg';
import freya11 from './assets/images/freya/freya11.jpg';
import freya12 from './assets/images/freya/freya12.jpg';
import freya13 from './assets/images/freya/freya13.jpg';
import freya14 from './assets/images/freya/freya14.jpg';
import freya15 from './assets/images/freya/freya15.jpg';
import freya16 from './assets/images/freya/freya16.jpg';
import freya17 from './assets/images/freya/freya17.jpg';
import freya18 from './assets/images/freya/freya18.jpg';

const photos = [
    { src: freya15, width: 210, height: 157 },
    { src: freya16, width: 210, height: 157 },
    { src: freya10, width: 157, height: 210 },
    { src: freya11, width: 157, height: 210 },
    { src: freya14, width: 210, height: 157 },
    { src: freya1, width: 157, height: 210 },
    { src: freya2, width: 210, height: 157 },
    { src: freya3, width: 157, height: 210 },
    { src: freya4, width: 157, height: 210 },
    { src: freya5, width: 157, height: 210 },
    { src: freya6, width: 157, height: 210 },
    { src: freya7, width: 157, height: 210 },
    { src: freya8, width: 157, height: 210 },
    { src: freya9, width: 157, height: 210 },
    { src: freya12, width: 157, height: 210 },
    { src: freya13, width: 157, height: 210 },
    { src: freya17, width: 210, height: 157 },
    { src: freya18, width: 210, height: 157 }
];

const Freya = () => {
    return (
        <DogDisplayPage photos={photos}>
            <h1 className="male">
                <i className="far fa-venus"></i>Freya (retired)
            </h1>
            <table className="dog-chart">
                <tbody>
                    <tr>
                        <td><span className="title">Name</span>CH SKIPNSTONES GODDESS AT LUNANOVA JWD</td>
                        <td><span className="title">Owner</span>CAROLYNNE ISSA / JOANNE SKALOS</td>
                        <td><span className="title">CKC Registration</span>#FY793888</td>
                    </tr>
                    <tr>
                        <td><span className="title">Sire</span>CAN/AM GCH INTERNATIONAL CH HI SEAS DR. ROMEO MACDUFF</td>
                        <td><span className="title">Dam</span>CH SKIPNSTONE DIAMOND IN THE RUFF</td>
                    </tr>
                    <tr>
                        <td><span className="title">Coat Type</span>WAVY</td>
                        <td><span className="title">Coat Colour</span>BLACK WITH WHITE MARKINGS</td>
                    </tr>
                    <tr>
                        <td><span className="title">OFA Hips</span>GOOD (ELBOWS NORMAL)</td>
                        <td><span className="title">CERF</span>NORMAL</td>
                        <td><span className="title">GM-1</span>normal</td>
                        <td><span className="title">JDCM</span>normal</td>
                    </tr>
                    <tr>
                        <td><span className="title">Optigen PRA</span>normal</td>
                        <td><span className="title">OPTIGEN EOPRA</span>NORMAL</td>
                        <td><span className="title">Optigen IC</span>normal</td>
                        <td><span className="title">MICROPHTHALMIA SYNDROME</span>CARRIER</td>
                    </tr>
                </tbody>
            </table>
        </DogDisplayPage>
    );
}

export default Freya;
