import React from 'react';
import DogDisplayPage from './DogDisplayPage';
import mimosa1 from './assets/images/mimosa/mimosa1.jpg';
import mimosa2 from './assets/images/mimosa/mimosa2.jpg';
import mimosa3 from './assets/images/mimosa/mimosa3.jpg';
import mimosa4 from './assets/images/mimosa/mimosa4.jpg';
import mimosa5 from './assets/images/mimosa/mimosa5.jpg';
import mimosa6 from './assets/images/mimosa/mimosa6.jpg';
import mimosa7 from './assets/images/mimosa/mimosa7.jpg';
import mimosa8 from './assets/images/mimosa/mimosa8.jpg';
import mimosa9 from './assets/images/mimosa/mimosa9.jpg';
import mimosa10 from './assets/images/mimosa/mimosa10.jpg';
import mimosa11 from './assets/images/mimosa/mimosa11.jpg';
import mimosa12 from './assets/images/mimosa/mimosa12.jpg';
import mimosa13 from './assets/images/mimosa/mimosa13.jpeg';
import mimosa14 from './assets/images/mimosa/mimosa14.jpg';
import mimosa15 from './assets/images/mimosa/mimosa15.jpeg';
import mimosa16 from './assets/images/mimosa/mimosa16.jpg';
import mimosa17 from './assets/images/mimosa/mimosa17.jpg';
import mimosa18 from './assets/images/mimosa/mimosa18.jpg';
import mimosa19 from './assets/images/mimosa/mimosa19.jpg';

const photos = [
    { src: mimosa1, width: 210, height: 157 },
    { src: mimosa2, width: 157, height: 210 },
    { src: mimosa3, width: 210, height: 157 },
    { src: mimosa4, width: 157, height: 210 },
    { src: mimosa5, width: 157, height: 210 },
    { src: mimosa6, width: 157, height: 210 },
    { src: mimosa7, width: 210, height: 157 },
    { src: mimosa8, width: 157, height: 210 },
    { src: mimosa9, width: 210, height: 157 },
    { src: mimosa10, width: 210, height: 157 },
    { src: mimosa11, width: 157, height: 210 },
    { src: mimosa12, width: 157, height: 210 },
    { src: mimosa13, width: 157, height: 210 },
    { src: mimosa14, width: 157, height: 210 },
    { src: mimosa15, width: 157, height: 210 },
    { src: mimosa16, width: 157, height: 210 },
    { src: mimosa17, width: 157, height: 210 },
    { src: mimosa18, width: 157, height: 210 },
    { src: mimosa19, width: 210, height: 210 },
];

const Mimosa = () => {
    return (
        <DogDisplayPage photos={photos}>
            <h1 className="female">
                <i className="far fa-venus"></i>Mimosa
            </h1>
            <table className="dog-chart">
                <tbody>
                    <tr>
                        <td><span className="title">Name</span>CH LUNANOVA BREWMASTERS WHATS THE STORY MORNING GLORY</td>
                        <td><span className="title">Owner</span>Carolynne Issa<br />JOANNE SKALOS</td>
                        <td><span className="title">CKC Registration</span>#HU4035908</td>
                    </tr>
                    <tr>
                        <td><span className="title">Sire</span>CH DREWSAR WNDRFF THE BREW MASTER</td>
                        <td><span className="title">Dam</span>GCH A LAGOA LIRIO DA LUNANOVA CGN</td>
                    </tr>
                    <tr>
                        <td><span className="title">Coat Type</span>WAVY</td>
                        <td><span className="title">Coat Colour</span>BROWN WITH WHITE MARKINGS (DOUBLE-TICKED)</td>
                    </tr>
                    <tr>
                        <td><span className="title">OFA Hips</span>GOOD (ELBOWS NORMAL)</td>
                        <td><span className="title">CERF</span>normal</td>
                        <td><span className="title">GM-1</span>normal</td>
                        <td><span className="title">JDCM</span>normal</td>
                    </tr>
                    <tr>
                        <td><span className="title">PRA</span>normal</td>
                        <td><span className="title">EOPRA</span>normal</td>
                        <td><span className="title">Improper Coat</span>normal</td>
                        <td><span className="title">MICROPHTHALMIA SYNDROME</span>normal</td>
                    </tr>
                    <tr>
                        <td><span className="title">CDDY/IVVD</span>normal</td>
                        <td><span className="title">CDPA</span>normal</td>
                    </tr>
                </tbody>
            </table>
        </DogDisplayPage>
    );
}
export default Mimosa;
