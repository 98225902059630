import React from 'react';
import DogDisplayPage from './DogDisplayPage';
import juno2 from './assets/images/juno/2.jpg';
import juno3 from './assets/images/juno/3.jpg';
import juno4 from './assets/images/juno/4.jpg';
import juno5 from './assets/images/juno/5.jpg';
import juno6 from './assets/images/juno/6.jpg';
import juno7 from './assets/images/juno/7.jpg';
import juno8 from './assets/images/juno/8.jpg';
import juno9 from './assets/images/juno/9.jpg';
import juno10 from './assets/images/juno/10.jpg';
import juno11 from './assets/images/juno/11.jpg';
import juno12 from './assets/images/juno/12.jpg';
import juno13 from './assets/images/juno/13.jpg';
import juno14 from './assets/images/juno/14.jpg';
import juno15 from './assets/images/juno/15.jpg';
import juno16 from './assets/images/juno/16.jpg';
import juno17 from './assets/images/juno/17.jpg';
import juno18 from './assets/images/juno/18.jpg';
import juno19 from './assets/images/juno/19.jpg';
import juno20 from './assets/images/juno/20.jpg';
import juno21 from './assets/images/juno/21.jpg';
import juno22 from './assets/images/juno/22.jpg';
import juno23 from './assets/images/juno/23.jpg';
import juno24 from './assets/images/juno/24.jpg';
import juno25 from './assets/images/juno/25.jpg';

const photos = [
  { src: juno2, width: 200, height: 125 },
  { src: juno3, width: 200, height: 125 },
  { src: juno4, width: 200, height: 125 },
  { src: juno5, width: 200, height: 125 },
  { src: juno6, width: 200, height: 125 },
  { src: juno7, width: 200, height: 125 },
  { src: juno8, width: 150, height: 200 },
  { src: juno9, width: 200, height: 200 },
  { src: juno10, width: 200, height: 200 },
  { src: juno11, width: 200, height: 180 },
  { src: juno12, width: 150, height: 200 },
  { src: juno13, width: 150, height: 200 },
  { src: juno14, width: 200, height: 200 },
  { src: juno15, width: 200, height: 200 },
  { src: juno16, width: 150, height: 200 },
  { src: juno17, width: 150, height: 200 },
  { src: juno18, width: 200, height: 125 },
  { src: juno19, width: 150, height: 200 },
  { src: juno20, width: 200, height: 200 },
  { src: juno21, width: 150, height: 200 },
  { src: juno22, width: 200, height: 200 },
  { src: juno23, width: 200, height: 125 },
  { src: juno24, width: 150, height: 200 },
  { src: juno25, width: 150, height: 200 },
];

const Juno = () => {
  return (
    <DogDisplayPage photos={photos}>
      <h1 className="female">
        <i className="far fa-venus"></i>Juno
      </h1>
      <table className="dog-chart">
        <tbody>
          <tr>
            <td><span className="title">Name</span>LUNANOVA DROPS OF JUPITER
            </td>
            <td><span className="title">Owner</span>Carolynne Issa</td>
            <td width="100px"><span className="title">CKC Registration</span>#JN4105180</td>
          </tr>
          <tr>
            <td><span className="title">Sire</span>BIGBAY MIGUEL DO ALGARVE</td>
            <td><span className="title">Dam</span>CH LUNANOVA'S LEGEND OF ZELDA AWD</td>
          </tr>
          <tr>
            <td><span className="title">Coat Type</span>Wavy</td>
            <td><span className="title">Coat Colour</span>BLACK</td>
          </tr>
          <tr>
            <td><span className="title">CERF</span>NORMAL</td>
            <td><span className="title">CDDY/IVVD</span>normal</td>
            <td><span className="title">CDPA</span>normal</td>
            <td><span className="title">EOPRA</span>normal</td>
          </tr>
          <tr>
            <td><span className="title">GM-1</span>normal</td>
            <td><span className="title">Improper Coat</span>normal</td>
            <td><span className="title">JDCM</span>normal</td>
            <td><span className="title">MICROPHTHALMIA SYNDROME</span>normal</td>
          </tr>
          <tr>
            <td><span className="title">OFA Hips</span>EXCELLENT (ELBOWS NORMAL)</td>
            <td><span className="title">PRA</span>normal</td>
          </tr>
        </tbody>
      </table>
    </DogDisplayPage>
  )

}
export default Juno;
