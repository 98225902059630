import React, { useState } from 'react';
import Candyd1 from './candy-d1';
import Candyw1 from './candy-w1';
import Candyw2 from './candy-w2';
import Candyw3 from './candy-w3';
import Candyw4 from './candy-w4';
import Candyw5 from './candy-w5';
import Candyw6 from './candy-w6';
import Candyw7 from './candy-w7';
import Scaryd1 from './scary-d1';
import Scaryw1 from './scary-w1';
import Scaryw2 from './scary-w2';
import Scaryw3 from './scary-w3';
import Scaryw4 from './scary-w4';
import Scaryw5 from './scary-w5';
import Scaryw6 from './scary-w6';
import Scaryw7 from './scary-w7';

import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import highlight from './assets/images/luna-highlight-2023.jpg';
import xray from './assets/images/luna-xray.jpg';
import scan from './assets/images/luna-scan.bmp';
import expect1 from './assets/images/expect/1.jpg';
import expect2 from './assets/images/expect/2.jpg';
import expect3 from './assets/images/expect/3.jpg';
import expect4 from './assets/images/expect/4.jpg';
import expect5 from './assets/images/expect/5.jpg';
import expect6 from './assets/images/expect/6.jpg';
import expect7 from './assets/images/expect/7.jpg';
import expect8 from './assets/images/expect/8.jpg';
import expect9 from './assets/images/expect/9.jpg';
import expect10 from './assets/images/expect/10.jpg';
import expect11 from './assets/images/expect/11.jpg';
import expect12 from './assets/images/expect/12.jpg';
import expect13 from './assets/images/expect/13.jpg';
import expect14 from './assets/images/expect/14.jpg';
import expect15 from './assets/images/expect/15.jpg';
import expect16 from './assets/images/expect/16.jpg';
import expect17 from './assets/images/expect/17.jpg';
import expect18 from './assets/images/expect/18.jpg';
import expect19 from './assets/images/expect/19.jpg';
import expect20 from './assets/images/expect/20.jpg';
import expect21 from './assets/images/expect/21.jpg';
import expect22 from './assets/images/expect/22.jpg';
import expect23 from './assets/images/expect/23.jpg';
import expect24 from './assets/images/expect/24.jpg';
import reunion2017 from './assets/images/expect/2017.jpg';
import reunion2018 from './assets/images/expect/2018.jpg';
import reunion2019 from './assets/images/expect/2019.jpg';
import reunion2022 from './assets/images/expect/2022.jpg';

const photos = [
    { src: reunion2017, width: 210, height: 140 },
    { src: reunion2018, width: 210, height: 140 },
    { src: reunion2019, width: 210, height: 120 },
    { src: reunion2022, width: 210, height: 140 },
    { src: expect1, width: 210, height: 157 },
    { src: expect2, width: 157, height: 210 },
    { src: expect3, width: 350, height: 150 },
    { src: expect4, width: 210, height: 157 },
    { src: expect5, width: 157, height: 210 },
    { src: expect6, width: 157, height: 210 },
    { src: expect7, width: 210, height: 157 },
    { src: expect8, width: 210, height: 157 },
    { src: expect9, width: 210, height: 157 },
    { src: expect10, width: 210, height: 157 },
    { src: expect11, width: 210, height: 157 },
    { src: expect12, width: 210, height: 157 },
    { src: expect13, width: 210, height: 157 },
    { src: expect14, width: 157, height: 210 },
    { src: expect15, width: 157, height: 210 },
    { src: expect16, width: 157, height: 210 },
    { src: expect17, width: 157, height: 210 },
    { src: expect18, width: 157, height: 210 },
    { src: expect19, width: 157, height: 210 },
    { src: expect20, width: 157, height: 210 },
    { src: expect21, width: 210, height: 157 },
    { src: expect22, width: 210, height: 157 },
    { src: expect23, width: 210, height: 157 },
    { src: expect24, width: 210, height: 157 },
];

const Expect = () => {
    const [index, setIndex] = useState(-1);
    const lightboxPhotos = photos.map(p => ({ src: p.src }))
    return (
        <div className="expect-container" photos={photos}>
            <div className="expect content">
                <div className="image-caption">
                    <img src={highlight} alt="Some Lunanova Forever Families at the 2023 Reunion" />
                    <small>Some LunaNova Forever Families at the 2023 Reunion</small>
                </div>
                <h1>What to expect from your experience with LunaNova</h1>
                <p>Most people find us on the internet, either through a google search, the CKC website, or the PWDCC website.  Others contact us because they have heard of us by word of mouth, either a referral from an existing family, or sometimes just simply by meeting one of our wonderful dogs while out and about, and requesting contact info.</p>
                <p>Once you make contact, we will respond promptly with a long and detailed email, or lengthy phone call if you prefer.  You will be asked to fill out the <a href="https://forms.gle/WwFxTU3bxb6yc65k6" target="_blank" rel="noopener noreferrer"><span>application</span></a>, and we will discuss your needs as far as timeline and wishes, and we will ask you any questions we have, based on your application.  If we approve your application, and you decide to choose us as your breeder, then your deposit will hold you a guaranteed spot.  It is preferable that you visit in person if possible to meet us and the dogs, but we do understand that some people live too far away, making multiple visits impossible, and therefore we will communicate via phone, facetime etc. until the day you take your pup home.  WE DO NOT SHIP PUPPIES.</p>
                <h2>LunaNova behind the scenes</h2>
                <PhotoAlbum photos={photos} layout="masonry" targetRowHeight={150} onClick={({ index }) => setIndex(index)} />
                <Lightbox
                    slides={lightboxPhotos}
                    open={index >= 0}
                    index={index}
                    close={() => setIndex(-1)}
                    plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                />
                <h2>Your experience with us</h2>
                <p>From the day you commit, you will be added to the emailing list, where we will share with you the ultrasound picture confirming pregnancy, and then the X-ray photo which tells us how many puppies we are expecting (usually accurate within 1 pup).  You will also be invited to join our closed Facebook Group (only open to families who own or are waiting for their Lunanova PWD), and asked if you wish to follow us on Instagram. If you have children (or children in your life on a regular basis), under the age of 12, you will be offered an interactive activity booklet that helps children to understand positive interaction between themselves and your puppy.</p>
                <div className="content-images">
                    <div className="image-caption">
                        <img src={xray} alt="Luna xray" />
                        <small>litter xray</small>
                    </div>
                    <div className="image-caption">
                        <img src={scan} alt="Luna scan" />
                        <small>litter scan</small>
                    </div>
                </div>
                <p>The day the puppies are born, you will be sent a birth announcement email, which will have a photo and detailed description of each pup.  Sex, colour, markings, coat type, time of birth, weight at birth, and kennel "theme" name and corresponding collar colour.  After that, you will receive a weekly update with photos and weights, so you can watch you puppy grow!  We encourage people to visit as much as they can (by appointment), and for those who can't visit, we will also be posting videos of the pups on our youtube channel for you to enjoy.</p>
                <h2>Puppy Experience: Candy litter</h2>
                <div className="galleries">
                    <div className="gallery-preview shadow">
                        <Candyd1 />
                        <div className="preview-title">
                            <span className="title">Day 1</span>
                        </div>
                    </div>
                    <div className="gallery-preview shadow">
                        <Candyw1 />
                        <div className="preview-title">
                            <span className="title">Week 1</span>
                        </div>
                    </div>
                    <div className="gallery-preview shadow">
                        <Candyw2 />
                        <div className="preview-title">
                            <span className="title">Week 2</span>
                        </div>
                    </div>
                    <div className="gallery-preview shadow">
                        <Candyw3 />
                        <div className="preview-title">
                            <span className="title">Week 3</span>
                        </div>
                    </div>
                    <div className="gallery-preview shadow">
                        <Candyw4 />
                        <div className="preview-title">
                            <span className="title">Week 4</span>
                        </div>
                    </div>
                    <div className="gallery-preview shadow">
                        <Candyw5 />
                        <div className="preview-title">
                            <span className="title">Week 5</span>
                        </div>
                    </div>
                    <div className="gallery-preview shadow">
                        <Candyw6 />
                        <div className="preview-title">
                            <span className="title">Week 6</span>
                        </div>
                    </div>
                    <div className="gallery-preview shadow">
                        <Candyw7 />
                        <div className="preview-title">
                            <span className="title">Week 7</span>
                        </div>
                    </div>
                </div>
                <p>Once the puppies are 7 weeks old, and have been temperament tested (using the Volhard Puppy Aptitude Test), it is time to decide which puppy is the right choice for each family!  You will be booked in to come and visit, or video chat, so we can discuss and decide which puppy is most ideal for you. </p>
                <p>Once the puppies are 8.5 weeks old and fully vet examined, vaccinated, fecal test done, and deemed healthy and normal, you will come and finalize all paperwork, and take your puppy home.  You will be sent home with a food sample, after a discussion about whether you wish to feed your dog kibble, or learn about the benefits of a raw diet.  Your puppy will be fully groomed, meaning bathed, blown out, ears cleaned, face trimmed, nails cut and paw pads shaved, and if you wish clipped in the "Lion Trim".  If you want to learn basic nail trimming and ear cleaning, we will teach you that day.  Your pup will be given a familiar smelling blanket and a couple of favourite toys.  You will be given 6 weeks free pet insurance, an offer to one year free membership to the Portuguese Water Dog Club of Canada, your puppy's vet records, a USB digital puppy pack that will include all information and health clearances on Sire and Dam.  It will also include many files that contain educational information for you, as well as all the photos I have taken in the 8 weeks.</p>
                <p>I will request a photo of you and your family with your new fur-baby, to share your joy with <a href="https://www.facebook.com/groups/1533131430251311" target="_blank" rel="noopener noreferrer">the Facebook group</a>.</p>
                <h2>Puppy Experience: Scary Things litter</h2>
                <div className="galleries">
                    <div className="gallery-preview shadow">
                        <Scaryd1 />
                        <div className="preview-title">
                            <span className="title">Day 1</span>
                        </div>
                    </div>
                    <div className="gallery-preview shadow">
                        <Scaryw1 />
                        <div className="preview-title">
                            <span className="title">Week 1</span>
                        </div>
                    </div>
                    <div className="gallery-preview shadow">
                        <Scaryw2 />
                        <div className="preview-title">
                            <span className="title">Week 2</span>
                        </div>
                    </div>
                    <div className="gallery-preview shadow">
                        <Scaryw3 />
                        <div className="preview-title">
                            <span className="title">Week 3</span>
                        </div>
                    </div>
                    <div className="gallery-preview shadow">
                        <Scaryw4 />
                        <div className="preview-title">
                            <span className="title">Week 4</span>
                        </div>
                    </div>
                    <div className="gallery-preview shadow">
                        <Scaryw5 />
                        <div className="preview-title">
                            <span className="title">Week 5</span>
                        </div>
                    </div>
                    <div className="gallery-preview shadow">
                        <Scaryw6 />
                        <div className="preview-title">
                            <span className="title">Week 6</span>
                        </div>
                    </div>
                    <div className="gallery-preview shadow">
                        <Scaryw7 />
                        <div className="preview-title">
                            <span className="title">Week 7</span>
                        </div>
                    </div>
                </div>
                <p>After you leave with your puppy that day, please know that your experience with us does not end there!!!  We are committed to providing all of our Lunanova PWD owners a lifetime of support!  We are only ever a phone call, text message or email away if you need advice or guidance in any way, or even just to share happy stories and photos of your dog!  Joining <a href="https://www.facebook.com/groups/1533131430251311" target="_blank" rel="noopener noreferrer">the Facebook group</a> also puts you in touch with many other Lunanova families, who also are always more than happy to share their experiences, or give helpful advice.</p>
                <blockquote>
                    Please don't forget your breeder<br /><br />
                    I knew from day one not every puppy could stay.<br />
                    Preparing them for life took many hours of each day.<br /><br />
                    I love every minute watching them develop and thrive<br />
                    Helping them discover the world fills me with pride.<br /><br />
                    The weeks are numbered, never enough time.<br />
                    I worry, even though I know the puppies will be fine.<br /><br />
                    I trust you with this puppy and hope you understand.<br />
                    I handpicked you, and expect you'll follow the plan.<br /><br />
                    Continue to provide new experiences each day.<br />
                    Never stop training your puppy, and always include play.<br /><br />
                    I will never forget my puppies and will always be here.<br />
                    Please provide them the best for the rest of their years.<br /><br />
                    No matter how long ago your baby was here.<br />
                    Not staying in touch is my biggest fear.
                </blockquote>
            </div>
        </div>
    )
}

export default Expect;
