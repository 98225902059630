import React from 'react';
import DogDisplayPage from './DogDisplayPage';
import jade1 from './assets/images/jade/jade1.jpg';
import jade2 from './assets/images/jade/jade2.jpg';
import jade3 from './assets/images/jade/jade3.jpg';
import jade4 from './assets/images/jade/jade4.jpg';
import jade5 from './assets/images/jade/jade5.jpg';
import jade6 from './assets/images/jade/jade6.jpg';
import jade7 from './assets/images/jade/jade7.jpg';
import jade8 from './assets/images/jade/jade8.jpg';
import jade9 from './assets/images/jade/jade9.jpg';
import jade10 from './assets/images/jade/jade10.jpg';
import jade11 from './assets/images/jade/jade11.jpg';
import jade12 from './assets/images/jade/jade12.jpg';
import jade13 from './assets/images/jade/jade13.jpg';

const photos = [
    { src: jade1, width: 210, height: 157 },
    { src: jade2, width: 210, height: 210 },
    { src: jade3, width: 210, height: 157 },
    { src: jade4, width: 210, height: 210 },
    { src: jade5, width: 210, height: 210 },
    { src: jade6, width: 210, height: 157 },
    { src: jade7, width: 157, height: 210 },
    { src: jade8, width: 210, height: 210 },
    { src: jade9, width: 210, height: 157 },
    { src: jade10, width: 210, height: 157 },
    { src: jade11, width: 210, height: 157 },
    { src: jade12, width: 157, height: 210 },
    { src: jade13, width: 157, height: 210 }
];

const Jade = () => {
    return (
        <DogDisplayPage photos={photos}>
            <h1 className="female">
                <i className="far fa-venus"></i>Jade (retired)
            </h1>
            <table className="dog-chart">
                <tbody>
                    <tr>
                        <td><span className="title">Name</span>LUNANOVA'S GEM OF SILVERSPRING JWD</td>
                        <td><span className="title">Owner</span>CAROLYNNE ISSA / JOANNE SKALOS</td>
                        <td><span className="title">CKC Registration</span>#CC596617</td>
                    </tr>
                    <tr>
                        <td><span className="title">Sire</span>BIS CH BIGBAY SELKIE'S WAGGISH MATE</td>
                        <td><span className="title">Dam</span>CH LUNANOVA MAIA'S PEQUENA SEREIA RN CGN WWD</td>
                    </tr>
                    <tr>
                        <td><span className="title">Coat Type</span>WAVY</td>
                        <td><span className="title">Coat Colour</span>WHITE WITH BLACK MARKINGS</td>
                    </tr>
                    <tr>
                        <td><span className="title">OFA Hips</span>GOOD</td>
                        <td><span className="title">CERF</span>NORMAL</td>
                        <td><span className="title">Optigen PRA</span>NORMAL</td>
                    </tr>
                    <tr>
                        <td><span className="title">Optigen IC</span>CARRIER</td>
                        <td><span className="title">GM-1</span>normal</td>
                        <td><span className="title">JDCM</span>normal</td>
                    </tr>
                </tbody>
            </table>
        </DogDisplayPage>
    )
}
export default Jade;
