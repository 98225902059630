import React from 'react';
import { Link } from "react-router-dom";
import Quotes from './Quotes';

import icon from './luna-icon-logo.svg';
// import contract from './assets/images/ContractMay2020.pdf';

const Footer = () => {
  return (
    <>
      <div className="footer-container">
        <Quotes />
        <div className="footer content">
          <div className="icon-spacer">
            <img src={icon} alt="LunaNova" />
          </div>
          <div className="footer-main">
            <div className="left">
              <span className="tagline">
                LunaNova Portuguese Water Dogs</span>
              <span className="copyright">Copyright © {(new Date().getFullYear())} LunaNova<br />All rights reserved.</span>
            </div>
            <div className="middle">
              <div className="footer-nav">
                <Link to="/">Home</Link>
                <div className="nav-group">
                  <Link to="/dogs">The Dogs</Link>
                  <ul>
                    <li><Link to="/dogs/archie">Archie</Link></li>
                    <li><Link to="/dogs/chilko">Chilko</Link></li>
                    <li><Link to="/dogs/dakota">Dakota</Link></li>
                    <li><Link to="/dogs/echo">Echo</Link></li>
                    <li><Link to="/dogs/fleur">Fleur</Link></li>
                    <li><Link to="/dogs/gibson">Gibson</Link></li>
                    <li><Link to="/dogs/juno">Juno</Link></li>
                    <li><Link to="/dogs/maggie">Maggie</Link></li>
                    <li><Link to="/dogs/mimosa">Mimosa</Link></li>
                    <li><Link to="/dogs/perry">Perry</Link></li>
                    <li><Link to="/dogs/scarlett">Scarlett</Link></li>
                    <li><Link to="/dogs/swift">Swift</Link></li>
                  </ul>
                </div>
                <div className="nav-group">
                  <Link to="/adoptions">Adoptions</Link>
                  <ul>
                    <li><a href="https://forms.gle/WwFxTU3bxb6yc65k6" target="_blank" rel="noopener noreferrer">Application</a></li>
                    <li>
                      {/* <a href={contract} target="_blank" rel="noopener noreferrer">Contract</a> */}
                    </li>
                  </ul>
                </div>
                <Link to="/expect">What to Expect</Link>
                <Link to="/about">About</Link>
                <Link to="/contact">Contact</Link>
              </div>
            </div>
            <div className="right">
              <div className="social">
                <a href="https://www.youtube.com/user/LUNANOVAPWDS" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"></i></a>
                <a href="https://www.instagram.com/lunanovapwds/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
              </div>
              <div className="contact">
                <p>Cambridge, Ontario<br />
                  lunanovapwds@gmail.com<br />
                  519-590-4619</p>
              </div>
              <div className="certificates">
                <span>CKC Registered</span>
                <span>PWDCC member in good standing</span>
                <span>City of Cambridge Licensed Kennel</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer;
