import React from 'react';
import DogDisplayPage from './DogDisplayPage';
import gypsy1 from './assets/images/gypsy/gypsy1.jpg';
import gypsy2 from './assets/images/gypsy/gypsy2.jpg';
import gypsy3 from './assets/images/gypsy/gypsy3.jpg';
import gypsy4 from './assets/images/gypsy/gypsy4.jpg';
import gypsy5 from './assets/images/gypsy/gypsy5.jpg';
import gypsy6 from './assets/images/gypsy/gypsy6.jpg';
import gypsy7 from './assets/images/gypsy/gypsy7.jpg';
import gypsy8 from './assets/images/gypsy/gypsy8.jpg';
import gypsy9 from './assets/images/gypsy/gypsy9.jpg';
import gypsy10 from './assets/images/gypsy/gypsy10.jpg';

const photos = [
    { src: gypsy1, width: 210, height: 157 },
    { src: gypsy2, width: 157, height: 210 },
    { src: gypsy3, width: 210, height: 157 },
    { src: gypsy4, width: 210, height: 157 },
    { src: gypsy5, width: 157, height: 210 },
    { src: gypsy6, width: 210, height: 157 },
    { src: gypsy7, width: 210, height: 157 },
    { src: gypsy8, width: 157, height: 210 },
    { src: gypsy9, width: 210, height: 157 },
    { src: gypsy10, width: 210, height: 157 }
];

const Gypsy = () => {
    return (
        <DogDisplayPage photos={photos}>
            <h1 className="male">
                <i className="far fa-venus"></i>Gypsy (retired)
            </h1>
            <table className="dog-chart">
                <tbody>
                    <tr>
                        <td><span className="title">Name</span>CH LUNANOVA'S BELA RAINHA CIGANA CGN WWD RN</td>
                        <td><span className="title">Owner</span>Carolynne Issa, LunaNova</td>
                        <td><span className="title">CKC Registration</span>#ZS477286</td>
                    </tr>
                    <tr>
                        <td><span className="title">Sire</span>AM/CAN CH TSAVO'S MACKINAC LIGHT OF DELSUR</td>
                        <td><span className="title">Dam</span>OLYMPUS GLICERIA MAIA ISSA</td>
                    </tr>
                    <tr>
                        <td><span className="title">Coat Type</span>Wavy</td>
                        <td><span className="title">Coat Colour</span>BROWN WITH WHITE MARKINGS</td>
                    </tr>
                    <tr>
                        <td><span className="title">OFA Hips</span>FAIR</td>
                        <td><span className="title">CERF</span>NORMAL</td>
                        <td><span className="title">Optigen PRA</span>normal</td>
                    </tr>
                    <tr>
                        <td><span className="title">Optigen IC</span>normal</td>
                        <td><span className="title">GM-1</span>normal</td>
                        <td><span className="title">JDCM</span>normal</td>
                    </tr>
                </tbody>
            </table>
        </DogDisplayPage>
    )
}
export default Gypsy;
