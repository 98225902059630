import React from 'react';
import { Outlet, Route, Routes } from "react-router-dom";
import DogsContent from './DogsContent';
import DogSidebar from './DogSidebar';
import Dahlia from './Dahlia';
import Scout from './Scout';
import Jade from './Jade';
import Perry from './Perry';
import Chilko from './Chilko';
import Gypsy from './Gypsy';
import Maia from './Maia';
import Ariel from './Ariel';
import Flower from './Flower';
import Zelda from './Zelda';
import Jasmine from './Jasmine';
import Elsa from './Elsa';
import Fleur from './Fleur';
import Mason from './Mason';
import Lily from './Lily';
import Freya from './Freya';
import Swift from './Swift';
import Echo from './Echo';
import Mimosa from './Mimosa';
import Gibson from './Gibson';
import Dakota from './Dakota';
import Archie from './Archie';
import Maggie from './Maggie';
import Juno from './Juno';
import Scarlett from './Scarlett';

const handleClick = (e) => {
    e.preventDefault();
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
    })
}

const Dogs = () => {
    return (
        <div className="dogs-container">
            <button onClick={handleClick} className="top-button"><i className="fas fa-arrow-up"></i></button>
            <div className="dogs content">
                <DogSidebar />
                <Routes>
                    <Route path="/" element={<DogsContent />} />
                    <Route path={"/dahlia"} element={<Dahlia />} />
                    <Route path={"/scout"} element={<Scout />} />
                    <Route path={"/jade"} element={<Jade />} />
                    <Route path={"/perry"} element={<Perry />} />
                    <Route path={"/chilko"} element={<Chilko />} />
                    <Route path={"/gypsy"} element={<Gypsy />} />
                    <Route path={"/maia"} element={<Maia />} />
                    <Route path={"/ariel"} element={<Ariel />} />
                    <Route path={"/flower"} element={<Flower />} />
                    <Route path={"/zelda"} element={<Zelda />} />
                    <Route path={"/jasmine"} element={<Jasmine />} />
                    <Route path={"/elsa"} element={<Elsa />} />
                    <Route path={"/fleur"} element={<Fleur />} />
                    <Route path={"/mason"} element={<Mason />} />
                    <Route path={"/lily"} element={<Lily />} />
                    <Route path={"/freya"} element={<Freya />} />
                    <Route path={"/swift"} element={<Swift />} />
                    <Route path={"/echo"} element={<Echo />} />
                    <Route path={"/mimosa"} element={<Mimosa />} />
                    <Route path={"/gibson"} element={<Gibson />} />
                    <Route path={"/dakota"} element={<Dakota />} />
                    <Route path={"/archie"} element={<Archie />} />
                    <Route path={"/maggie"} element={<Maggie />} />
                    <Route path={"/juno"} element={<Juno />} />
                    <Route path={"/scarlett"} element={<Scarlett />} />
                </Routes>
                <Outlet />
            </div>
        </div>
    );

};

export default Dogs;
