import React from 'react';
import DogDisplayPage from './DogDisplayPage';
import elsa1 from './assets/images/elsa/elsa1.jpg';
import elsa2 from './assets/images/elsa/elsa2.jpg';
import elsa3 from './assets/images/elsa/elsa3.jpg';
import elsa4 from './assets/images/elsa/elsa4.jpg';
import elsa5 from './assets/images/elsa/elsa5.jpg';
import elsa6 from './assets/images/elsa/elsa6.jpg';
import elsa7 from './assets/images/elsa/elsa7.jpg';
import elsa8 from './assets/images/elsa/elsa8.jpg';
import elsa9 from './assets/images/elsa/elsa9.jpg';
import elsa10 from './assets/images/elsa/elsa10.jpg';
import elsa11 from './assets/images/elsa/elsa11.jpg';
import elsa13 from './assets/images/elsa/elsa13.jpg';

const photos = [
    { src: elsa1, width: 157, height: 210 },
    { src: elsa2, width: 210, height: 210 },
    { src: elsa3, width: 210, height: 157 },
    { src: elsa4, width: 157, height: 210 },
    { src: elsa5, width: 210, height: 210 },
    { src: elsa6, width: 210, height: 157 },
    { src: elsa7, width: 157, height: 210 },
    { src: elsa8, width: 157, height: 210 },
    { src: elsa9, width: 210, height: 157 },
    { src: elsa10, width: 210, height: 210 },
    { src: elsa11, width: 157, height: 210 },
    { src: elsa13, width: 210, height: 157 }
];

const Elsa = () => {
    return (
        <DogDisplayPage photos={photos}>
            <h1 className="female">
                <i className="far fa-venus"></i>Elsa (retired)
            </h1>
            <table className="dog-chart">
                <tbody>
                    <tr>
                        <td><span className="title">Name</span>CH LUNANOVA LET THE STORM RAGE ON</td>
                        <td><span className="title">Owner</span>CAROLYNNE ISSA / JOANNE SKALOS</td>
                        <td><span className="title">CKC Registration</span>#EG706672</td>
                    </tr>
                    <tr>
                        <td><span className="title">Sire</span>AM/CAN CH CASCADE SKIPNSTONES ACROSS THE RIO</td>
                        <td><span className="title">Dam</span>LUNANOVA'S GEM OF SILVERSPRING</td>
                    </tr>
                    <tr>
                        <td><span className="title">Coat Type</span>WAVY</td>
                        <td><span className="title">Coat Colour</span>BLACK WITH WHITE MARKINGS</td>
                    </tr>
                    <tr>
                        <td><span className="title">OFA Hips</span>FAIR</td>
                        <td><span className="title">CERF</span>NORMAL</td>
                        <td><span className="title">GM-1</span>normal</td>
                        <td><span className="title">JDCM</span>normal</td>
                    </tr>
                    <tr>
                        <td><span className="title">Optigen PRA</span>normal</td>
                        <td><span className="title">OPTIGEN EOPRA</span>NORMAL</td>
                        <td><span className="title">Optigen IC</span>normal</td>
                        <td><span className="title">MICROPHTHALMIA SYNDROME</span>normal</td>
                    </tr>
                </tbody>
            </table>
        </DogDisplayPage>
    );
}

export default Elsa;
