import React from 'react';
import DogDisplayPage from './DogDisplayPage';
import maia1 from './assets/images/maia/maia1.jpg';
import maia2 from './assets/images/maia/maia2.jpg';
import maia3 from './assets/images/maia/maia3.jpg';
import maia4 from './assets/images/maia/maia4.jpg';
import maia5 from './assets/images/maia/maia5.jpg';
import maia6 from './assets/images/maia/maia6.jpg';
import maia7 from './assets/images/maia/maia7.jpg';
import maia8 from './assets/images/maia/maia8.jpg';
import maia9 from './assets/images/maia/maia9.jpg';
import maia10 from './assets/images/maia/maia10.jpg';
import maia11 from './assets/images/maia/maia11.jpg';
import maia12 from './assets/images/maia/maia12.jpg';
import maia13 from './assets/images/maia/maia13.jpg';
import maia14 from './assets/images/maia/maia14.jpg';
import maia15 from './assets/images/maia/maia15.jpg';
import maia16 from './assets/images/maia/maia16.jpg';
import maia16a from './assets/images/maia/maia16a.jpg';
import maia16b from './assets/images/maia/maia16b.jpg';
import maia17 from './assets/images/maia/maia17.jpg';
import maia18 from './assets/images/maia/maia18.jpg';
import maia19 from './assets/images/maia/maia19.jpg';
import maia19a from './assets/images/maia/maia19a.jpg';
import maia20 from './assets/images/maia/maia20.jpg';
import maia21 from './assets/images/maia/maia21.jpg';
import maia22 from './assets/images/maia/maia22.jpg';
import maia23 from './assets/images/maia/maia23.jpg';
import maia23a from './assets/images/maia/maia23a.jpg';
import maia24 from './assets/images/maia/maia24.jpg';
import maia25 from './assets/images/maia/maia25.jpg';
import maia26 from './assets/images/maia/maia26.jpg';
import maia27 from './assets/images/maia/maia27.jpg';
import maia28 from './assets/images/maia/maia28.jpg';
import maia29 from './assets/images/maia/maia29.jpg';
import maia30 from './assets/images/maia/maia30.jpg';
import maia32 from './assets/images/maia/maia32.jpg';
import maia33 from './assets/images/maia/maia33.jpg';
import maia34 from './assets/images/maia/maia34.jpg';
import maia35 from './assets/images/maia/maia35.jpg';
import maia35a from './assets/images/maia/maia35a.jpg';
import maia36 from './assets/images/maia/maia36.jpg';
import maia37 from './assets/images/maia/maia37.jpg';
import maia38 from './assets/images/maia/maia38.jpg';
import maia39a from './assets/images/maia/maia39a.jpg';
import maia39 from './assets/images/maia/maia39.jpg';
import maia40 from './assets/images/maia/maia40.jpg';
import maia41 from './assets/images/maia/maia41.jpg';
import rainbow from './assets/images/rainbow.svg';

const photos = [
    { src: maia1, width: 200, height: 160 },
    { src: maia2, width: 200, height: 160 },
    { src: maia3, width: 140, height: 200 },
    { src: maia4, width: 200, height: 160 },
    { src: maia5, width: 200, height: 160 },
    { src: maia6, width: 200, height: 160 },
    { src: maia7, width: 140, height: 200 },
    { src: maia8, width: 200, height: 160 },
    { src: maia9, width: 200, height: 180 },
    { src: maia10, width: 200, height: 180 },
    { src: maia11, width: 200, height: 160 },
    { src: maia12, width: 200, height: 160 },
    { src: maia13, width: 200, height: 160 },
    { src: maia14, width: 140, height: 200 },
    { src: maia15, width: 140, height: 200 },
    { src: maia16, width: 200, height: 160 },
    { src: maia16a, width: 200, height: 160 },
    { src: maia16b, width: 140, height: 200 },
    { src: maia17, width: 200, height: 160 },
    { src: maia18, width: 140, height: 200 },
    { src: maia19, width: 120, height: 200 },
    { src: maia19a, width: 200, height: 160 },
    { src: maia20, width: 140, height: 200 },
    { src: maia21, width: 200, height: 140 },
    { src: maia22, width: 200, height: 160 },
    { src: maia23, width: 200, height: 160 },
    { src: maia23a, width: 140, height: 200 },
    { src: maia24, width: 140, height: 200 },
    { src: maia25, width: 140, height: 200 },
    { src: maia26, width: 140, height: 200 },
    { src: maia27, width: 140, height: 200 },
    { src: maia28, width: 140, height: 200 },
    { src: maia29, width: 140, height: 200 },
    { src: maia30, width: 140, height: 200 },
    { src: maia32, width: 140, height: 200 },
    { src: maia33, width: 140, height: 200 },
    { src: maia34, width: 140, height: 200 },
    { src: maia35, width: 140, height: 200 },
    { src: maia35a, width: 120, height: 200 },
    { src: maia36, width: 140, height: 200 },
    { src: maia37, width: 80, height: 180 },
    { src: maia38, width: 120, height: 200 },
    { src: maia39, width: 200, height: 120 },
    { src: maia39a, width: 80, height: 180 },
    { src: maia40, width: 140, height: 200 },
    { src: maia41, width: 200, height: 120 },
];

const Maia = () => {
    return (
        <DogDisplayPage photos={photos}>
            <h1 className="male">
                <span className="rainbow-dog">
                    <i className="far fa-venus"></i>
                    <img src={rainbow} alt="rainbow icon" className="rainbow-icon large" />
                    Maia
                </span>
            </h1>
            <table className="dog-chart">
                <tbody>
                    <tr>
                        <td><span className="title">Name</span>Olympus Gliceria Maia Issa</td>
                        <td><span className="title">Owner</span>Carolynne Issa, LunaNova</td>
                        <td><span className="title">CKC Registration</span>#UQ259714</td>
                    </tr>
                    <tr>
                        <td><span className="title">Sire</span>CH Magnolia's Apollo Sir</td>
                        <td><span className="title">Dam</span>Mount Olympus Venus Mudlushes</td>
                    </tr>
                    <tr>
                        <td><span className="title">Coat Type</span>Wavy</td>
                        <td><span className="title">Coat Colour</span>White with black markings</td>
                    </tr>
                    <tr>
                        <td><span className="title">OVC Hips</span>normal</td>
                        <td><span className="title">CERF</span>NORMAL (March 2012) </td>
                        <td><span className="title">Optigen PRA</span>normal</td>
                    </tr>
                    <tr>
                        <td><span className="title">Optigen IC</span>normal</td>
                        <td><span className="title">GM-1</span>normal</td>
                        <td><span className="title">JDCM</span>normal</td>
                    </tr>
                </tbody>
            </table>
        </DogDisplayPage>
    )
}
export default Maia;
