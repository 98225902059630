import React from 'react';
import { Link } from "react-router-dom";
import DogSidebar from './DogSidebar';
import ariel from './assets/images/ariel-thumb.jpg';
import flower from './assets/images/flower-thumb.jpg';
import maia from './assets/images/maia-thumb.jpg';
import rainbow from './assets/images/rainbow.svg';

const RainbowBridge = () => {
    return (
        <div className="dogs-container">
            <div className="dogs content">
                <DogSidebar />
                <div className="dog-page">
                    <h1>Rainbow Bridge</h1>
                    <div className="rainbow">
                        <p>Just this side of heaven is a place called Rainbow Bridge.</p>
                        <p>When an animal dies that has been especially close to someone here, that pet goes to Rainbow Bridge. There are meadows and hills for all of our special friends so they can run and play together. There is plenty of food, water and sunshine, and our friends are warm and comfortable.</p>
                        <p>All the animals who had been ill and old are restored to health and vigor. Those who were hurt or maimed are made whole and strong again, just as we remember them in our dreams of days and times gone by. The animals are happy and content, except for one small thing; they each miss someone very special to them, who had to be left behind.</p>
                        <p>They all run and play together, but the day comes when one suddenly stops and looks into the distance. His bright eyes are intent. His eager body quivers. Suddenly he begins to run from the group, flying over the green grass, his legs carrying him faster and faster.</p>
                        <p>You have been spotted, and when you and your special friend finally meet, you cling together in joyous reunion, never to be parted again. The happy kisses rain upon your face; your hands again caress the beloved head, and you look once more into the trusting eyes of your pet, so long gone from your life but never absent from your heart.</p>
                        <p>Then you cross Rainbow Bridge together...</p>
                    </div>

                    <div className="rainbow-dogs">
                        <Link to="/dogs/maia" className='dog'>
                            <img src={maia} alt="LunaNova Maia" className='profile' />
                            <span className="rainbow-dog">
                                <img src={rainbow} alt="rainbow icon" className="rainbow-icon small" />
                                Maia
                            </span>
                        </Link>
                        <Link to="/dogs/ariel" className="dog">
                            <img src={ariel} alt="LunaNova Ariel" className='profile' />
                            <span className="rainbow-dog">
                                <img src={rainbow} alt="rainbow icon" className="rainbow-icon small" />
                                Ariel
                            </span>
                        </Link>
                        <Link to="/dogs/flower" className="dog">
                            <img src={flower} alt="LunaNova Flower" className='profile' />
                            <span className="rainbow-dog">
                                <img src={rainbow} alt="rainbow icon" className="rainbow-icon small" />
                                Flower
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default RainbowBridge;
