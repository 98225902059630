import React from 'react';
import { Link } from "react-router-dom";
import DogSidebar from './DogSidebar';
import elsa from './assets/images/elsa-thumb.jpg';
import freya from './assets/images/freya-thumb.jpg';
import jasmine from './assets/images/jasmine-thumb.jpg';
import lily from './assets/images/lily-thumb.jpg';
import mason from './assets/images/mason-thumb.jpg';
import zelda from './assets/images/zelda-thumb.jpg';
import dahlia from './assets/images/dahlia-thumb.jpg';
import gypsy from './assets/images/gypsy-thumb.jpg';
import jade from './assets/images/jade-thumb.jpg';
import scout from './assets/images/scout-thumb.jpg';

class Retired extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="dogs-container">
        <div className="dogs content">
          <DogSidebar />
          <div className="dog-page">
            <h1>Retired Dogs</h1>
            <p>We are SO very proud of all of the dogs featured here!  These dogs represent the history of LunaNova, and are to be thanked and acknowledged for each and every puppy we continue to breed! We love and appreciate them deeply, for being the past, present, and future of LunaNova!</p>
            <p>(None of the dogs featured on this page are up for adoption)</p>
            <div className="dog-features">
              <Link to="/dogs/dahlia" className="dog">
                <img src={dahlia} alt="LunaNova Dahlia" />
                <span className="female">Dahlia</span>
              </Link>
              <Link to="/dogs/elsa" className="dog">
                <img src={elsa} alt="LunaNova Elsa" />
                <span className="female">Elsa</span>
              </Link>
              <Link to="/dogs/freya" className="dog">
                <img src={freya} alt="LunaNova Freya" />
                <span className="female">Freya</span>
              </Link>
              <Link to="/dogs/gypsy" className="dog">
                <img src={gypsy} alt="LunaNova Gypsy" />
                <span className="female">Gypsy</span>
              </Link>
              <Link to="/dogs/jade" className="dog">
                <img src={jade} alt="LunaNova Jade" />
                <span className="female">Jade</span>
              </Link>
              <Link to="/dogs/jasmine" className="dog">
                <img src={jasmine} alt="LunaNova Jasmine" />
                <span className="female">Jasmine</span>
              </Link>
              <Link to="/dogs/lily" className="dog">
                <img src={lily} alt="LunaNova Lily" />
                <span className="female">Lily</span>
              </Link>
              <Link to="/dogs/mason" className="dog">
                <img src={mason} alt="LunaNova Mason" />
                <span>Mason</span>
              </Link>
              <Link to="/dogs/scout" className="dog">
                <img src={scout} alt="LunaNova Scout" />
                <span className="female">Scout</span>
              </Link>
              <Link to="/dogs/zelda" className="dog">
                <img src={zelda} alt="LunaNova Zelda" />
                <span className="female">Zelda</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Retired;
