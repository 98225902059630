import React from 'react';
import DogDisplayPage from './DogDisplayPage';
import ariel1 from './assets/images/ariel/ariel1.jpg';
import ariel3 from './assets/images/ariel/ariel3.jpg';
import ariel4 from './assets/images/ariel/ariel4.jpg';
import ariel5 from './assets/images/ariel/ariel5.jpg';
import ariel6 from './assets/images/ariel/ariel6.jpg';
import ariel7 from './assets/images/ariel/ariel7.jpg';
import ariel8 from './assets/images/ariel/ariel8.jpg';
import ariel9 from './assets/images/ariel/ariel9.jpg';
import ariel10 from './assets/images/ariel/ariel10.jpg';
import ariel11 from './assets/images/ariel/ariel11.jpg';
import ariel12 from './assets/images/ariel/ariel12.jpg';
import ariel13 from './assets/images/ariel/ariel13.jpg';
import ariel14 from './assets/images/ariel/ariel14.jpg';
import ariel15 from './assets/images/ariel/ariel15.jpg';
import ariel16 from './assets/images/ariel/ariel16.jpg';
import ariel17 from './assets/images/ariel/ariel17.jpg';
import ariel18 from './assets/images/ariel/ariel18.jpg';
import ariel19 from './assets/images/ariel/ariel19.jpg';
import ariel20 from './assets/images/ariel/ariel20.jpg';
import rainbow from './assets/images/rainbow.svg';

const photos = [
    { src: ariel1, width: 210, height: 183 },
    { src: ariel3, width: 210, height: 157 },
    { src: ariel4, width: 210, height: 157 },
    { src: ariel5, width: 210, height: 157 },
    { src: ariel6, width: 210, height: 157 },
    { src: ariel7, width: 210, height: 157 },
    { src: ariel8, width: 210, height: 157 },
    { src: ariel9, width: 210, height: 157 },
    { src: ariel10, width: 210, height: 157 },
    { src: ariel11, width: 210, height: 157 },
    { src: ariel12, width: 210, height: 157 },
    { src: ariel13, width: 210, height: 157 },
    { src: ariel14, width: 210, height: 157 },
    { src: ariel15, width: 210, height: 157 },
    { src: ariel16, width: 210, height: 157 },
    { src: ariel17, width: 210, height: 183 },
    { src: ariel18, width: 210, height: 183 },
    { src: ariel19, width: 157, height: 210 },
    { src: ariel20, width: 210, height: 157 },
];

const Ariel = () => {
    return (
        <DogDisplayPage photos={photos}>
            <h1 className="male">
                <span className="rainbow-dog">
                    <i className="far fa-venus"></i>
                    <img src={rainbow} alt="rainbow icon" className="rainbow-icon large" />
                    Ariel
                </span>
            </h1>
            <table className="dog-chart">
                <tbody>
                    <tr>
                        <td><span className="title">Name</span>ch lunanova maia's pequena sereia rn cgn wwd</td>
                        <td><span className="title">Owner</span>carolynne issa, lunanova</td>
                        <td><span className="title">CKC Registration</span>#YA388861</td>
                    </tr>
                    <tr>
                        <td><span className="title">Sire</span>ch ondulado's mariposa pescador awd wwd</td>
                        <td><span className="title">Dam</span>OLYMPUS GLICERIA MAIA ISSA</td>
                    </tr>
                    <tr>
                        <td><span className="title">Coat Type</span>Wavy</td>
                        <td><span className="title">Coat Colour</span>BLACK WITH WHITE MARKINGS</td>
                    </tr>
                    <tr>
                        <td><span className="title">OFA Hips</span>GOOD</td>
                        <td><span className="title">CERF</span>NORMAL</td>
                        <td><span className="title">Optigen PRA</span>NORMAL</td>
                    </tr>
                    <tr>
                        <td><span className="title">Optigen IC</span>CARRIER</td>
                        <td><span className="title">GM-1</span>normal</td>
                        <td><span className="title">JDCM</span>normal</td>
                    </tr>
                </tbody>
            </table>
        </DogDisplayPage>
    )
}
export default Ariel;
