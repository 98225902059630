import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const CustomLink = ({ children, hash }) => {
    const location = useLocation();
    if (location.pathname !== '/dogs') {
        return <Link to={`/dogs${hash}`}>{children}</Link>
    }

    return <a href={hash} onClick={(event) => {
        event.preventDefault();
        const anchorTarget = document.getElementById(hash.substring(1));
        console.log(anchorTarget)
        anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }}>{children}</a>
}

const DogSidebar = () => {
    const { location } = useLocation();

    return (
        <div className="side" id="mobile-menu">
            <input type="checkbox" />
            <span></span>
            <span></span>
            <span></span>
            <ul className="sidebar-nav">
                <li className="sidebar-section">
                    <CustomLink location={location} hash="#lunadogs">
                        LunaNova Dogs
                    </CustomLink>

                    <ul>
                        <li>
                            <Link to="/dogs/archie">
                                <i className="far fa-mars"></i> Archie
                            </Link>
                        </li>
                        <li>
                            <Link to="/dogs/chilko">
                                <i className="far fa-mars"></i> Chilko
                            </Link>
                        </li>
                        <li>
                            <Link to="/dogs/dakota">
                                <i className="far fa-venus"></i> Dakota
                            </Link>
                        </li>
                        <li>
                            <Link to="/dogs/echo" className="female">
                                <i className="far fa-venus"></i> Echo
                            </Link>
                        </li>
                        <li>
                            <Link to="/dogs/fleur" className="female">
                                <i className="far fa-venus"></i> Fleur
                            </Link>
                        </li>
                        <li>
                            <Link to="/dogs/gibson" className="female">
                                <i className="far fa-venus"></i> Gibson
                            </Link>
                        </li>
                        <li>
                            <Link to="/dogs/juno">
                                <i className="far fa-venus"></i> Juno
                            </Link>
                        </li>
                        <li>
                            <Link to="/dogs/maggie">
                                <i className="far fa-venus"></i> Maggie
                            </Link>
                        </li>
                        <li>
                            <Link to="/dogs/mimosa">
                                <i className="far fa-venus"></i> Mimosa
                            </Link>
                        </li>
                        <li>
                            <Link to="/dogs/perry">
                                <i className="far fa-mars"></i> Perry
                            </Link>
                        </li>
                        <li>
                            <Link to="/dogs/scarlett">
                                <i className="far fa-venus"></i> Scarlett
                            </Link>
                        </li>
                        <li>
                            <Link to="/dogs/swift" className="female">
                                <i className="far fa-venus"></i> Swift
                            </Link>
                        </li>
                    </ul>
                </li>
                <li className="sidebar-section">
                    <Link to="/retired-dogs">Retired Dogs</Link>
                </li>
                <li className="sidebar-section">
                    <Link to="/rainbow-bridge">Rainbow Bridge</Link>
                </li>
                <li className="sidebar-section">
                    <CustomLink location={location} hash="#about">
                        About PWDs
                    </CustomLink>
                    <ul>
                        <li>
                            <CustomLink location={location} hash="#origin">
                                Origin &amp; Purpose
                            </CustomLink>
                        </li>
                        <li>
                            <CustomLink location={location} hash="#appearance">
                                Appearance &amp; Temperament
                            </CustomLink>
                        </li>
                        <li>
                            <CustomLink location={location} hash="#health">
                                Health Issues
                            </CustomLink>
                        </li>
                        <li>
                            <CustomLink location={location} hash="#supplements">
                                Hip & Joint Supplements
                            </CustomLink>
                        </li>
                        <li>
                            <CustomLink location={location} hash="#standard">
                                Breed Standard
                            </CustomLink>
                        </li>
                    </ul>
                </li>
                <li className="sidebar-section">
                    <CustomLink location={location} hash="#right">
                        Is a PWD right for you?
                    </CustomLink>
                </li>
                <li className="sidebar-section">
                    <CustomLink location={location} hash="#cost">
                        Cost breakdown
                    </CustomLink>
                </li>
            </ul>
        </div>
    );

}

export default DogSidebar;
