import React from 'react';
import DogDisplayPage from './DogDisplayPage';
import scout1 from './assets/images/scout/scout1.jpg';
import scout2 from './assets/images/scout/scout2.jpg';
import scout3 from './assets/images/scout/scout3.jpg';
import scout4 from './assets/images/scout/scout4.jpg';
import scout5 from './assets/images/scout/scout5.jpg';
import scout6 from './assets/images/scout/scout6.jpg';
import scout7 from './assets/images/scout/scout7.jpg';
import scout8 from './assets/images/scout/scout8.jpg';
import scout9 from './assets/images/scout/scout9.jpg';
import scout10 from './assets/images/scout/scout10.jpg';
import scout11 from './assets/images/scout/scout11.jpg';

const photos = [
    { src: scout1, width: 210, height: 157 },
    { src: scout2, width: 210, height: 210 },
    { src: scout3, width: 210, height: 157 },
    { src: scout4, width: 210, height: 157 },
    { src: scout5, width: 210, height: 157 },
    { src: scout6, width: 210, height: 157 },
    { src: scout7, width: 157, height: 210 },
    { src: scout8, width: 210, height: 157 },
    { src: scout9, width: 210, height: 157 },
    { src: scout10, width: 210, height: 157 },
    { src: scout11, width: 210, height: 157 }
];

const Scout = () => {
    return (
        <DogDisplayPage photos={photos}>
            <h1 className="female">
                <i className="far fa-venus"></i>Scout (retired)
            </h1>
            <table className="dog-chart">
                <tbody>
                    <tr>
                        <td><span className="title">Name</span>CH LUNANOVA'S BE MY SILVERSPRING CGN JWD AWD RN</td>
                        <td><span className="title">Owner</span>CAROLYNNE ISSA / JOANNE SKALOS</td>
                        <td><span className="title">CKC Registration</span>#BL546809</td>
                    </tr>
                    <tr>
                        <td><span className="title">Sire</span>BIS CH BIGBAY SELKIE'S WAGGISH MATE</td>
                        <td><span className="title">Dam</span>OLYMPUS GLICERIA MAIA ISSA</td>
                    </tr>
                    <tr>
                        <td><span className="title">Coat Type</span>Wavy</td>
                        <td><span className="title">Coat Colour</span>WHITE WITH BLACK MARKINGS</td>
                    </tr>
                    <tr>
                        <td><span className="title">OFA Hips</span>GOOD</td>
                        <td><span className="title">CERF</span>NORMAL</td>
                        <td><span className="title">Optigen PRA</span>normal</td>
                    </tr>
                    <tr>
                        <td><span className="title">Optigen IC</span>normal</td>
                        <td><span className="title">GM-1</span>normal</td>
                        <td><span className="title">JDCM</span>normal</td>
                    </tr>
                </tbody>
            </table>
        </DogDisplayPage>
    )
}
export default Scout;
