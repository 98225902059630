import React from 'react';
import DogDisplayPage from './DogDisplayPage';
import jasmine1 from './assets/images/jasmine/jasmine1.jpg';
import jasmine2 from './assets/images/jasmine/jasmine2.jpg';
import jasmine3 from './assets/images/jasmine/jasmine3.JPG';
import jasmine4 from './assets/images/jasmine/jasmine4.jpg';
import jasmine5 from './assets/images/jasmine/jasmine5.jpg';
import jasmine6 from './assets/images/jasmine/jasmine6.JPG';
import jasmine7 from './assets/images/jasmine/jasmine7.jpg';
import jasmine8 from './assets/images/jasmine/jasmine8.jpg';
import jasmine9 from './assets/images/jasmine/jasmine9.jpg';
import jasmine10 from './assets/images/jasmine/jasmine10.JPG';

const photos = [
    { src: jasmine1, width: 210, height: 157 },
    { src: jasmine2, width: 157, height: 210 },
    { src: jasmine3, width: 157, height: 210 },
    { src: jasmine4, width: 157, height: 210 },
    { src: jasmine5, width: 157, height: 210 },
    { src: jasmine6, width: 157, height: 210 },
    { src: jasmine7, width: 210, height: 157 },
    { src: jasmine8, width: 1.75, height: 2 },
    { src: jasmine9, width: 210, height: 210 },
    { src: jasmine10, width: 157, height: 210 }
];
const Jasmine = () => {
    return (
        <DogDisplayPage photos={photos}>
            <h1 className="male">
                <i className="far fa-venus"></i>Jasmine (retired)
            </h1>
            <table className="dog-chart">
                <tbody>
                    <tr>
                        <td><span className="title">Name</span>LUNANOVA'S REBEL AT SILVERSPRING</td>
                        <td><span className="title">Owner</span>CAROLYNNE ISSA / JOANNE SKALOS</td>
                        <td><span className="title">CKC Registration</span>#DS672966</td>
                    </tr>
                    <tr>
                        <td><span className="title">Sire</span>AM CH COSMOS LET'S CELEBRATE CWDX</td>
                        <td><span className="title">Dam</span>CH LUNANOVA'S BE MY SILVERSPRING JWD AWD RN</td>
                    </tr>
                    <tr>
                        <td><span className="title">Coat Type</span>WAVY</td>
                        <td><span className="title">Coat Colour</span>BLACK WITH WHITE MARKINGS</td>
                    </tr>
                    <tr>
                        <td><span className="title">OFA Hips</span>GOOD</td>
                        <td><span className="title">OFA Elbows</span>NORMAL</td>
                        <td><span className="title">CERF</span>NORMAL</td>
                        <td><span className="title">Optigen PRA</span>normal</td>
                        <td><span className="title">Optigen EOPRA</span>normal</td>
                    </tr>
                    <tr>
                        <td><span className="title">Optigen IC</span>normal</td>
                        <td><span className="title">GM-1</span>normal</td>
                        <td><span className="title">JDCM</span>normal</td>
                        <td><span className="title">MICROPHTHALMIA SYNDROME</span>normal</td>
                    </tr>
                </tbody>
            </table>
        </DogDisplayPage>
    )
}
export default Jasmine;
