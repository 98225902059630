import React from 'react';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

const Quotes = () => {
    return (
        <div className="carousel-wrapper">
            <CarouselProvider
                naturalSlideWidth={100}
                naturalSlideHeight={50}
                totalSlides={51}
                isPlaying={true}
            >
                <Slider>
                    <Slide index={0}>"The Portuguese Water Dog is an animal of spirited disposition: self-willed, brave and very resistant to fatigue.  He is a dog of exceptional intelligence and a loyal companion, who obeys his master with facility and apparent pleasure." - Kathryn Braund</Slide>
                    <Slide index={1}>"Dogs have given us their absolute all.  We are the center of their universe.  We are the focus of their love and faith and trust.  They serve us in return for scraps.  It is without a doubt the best deal man has ever made." - Roger Caras</Slide>
                    <Slide index={2}>"Dogs are not our whole life, but they make our lives whole." - Roger Caras</Slide>
                    <Slide index={3}>"My goal in life is to be as good of a person my dog already thinks I am." -Unknown</Slide>
                    <Slide index={4}>"Channeling the stamina, intelligence and exuberance of the dynamic Portuguese Water Dog is a highly rewarding, although noteworthy challenge for any dog fancier." - Karen Miller-Stebbings</Slide>
                    <Slide index={5}>"Once known to a Portuguese Water Dog, expect a raucous welcome, with an offering of a favourite toy, accompanied by a cacophony of extraordinary sounds." - Karen Miller-Stebbings</Slide>
                    <Slide index={6}>"The robustness and high spirit of this fisherman&#39;s dog must be understood as natural exuberance." - Karen Miller-Stebbings</Slide>
                    <Slide index={7}>"He bonds readily and is loyal to his crewmates." - Karen Miller-Stebbings</Slide>
                    <Slide index={8}>"If you are a host to your guest, be a host to his dog also." - Russian Proverb</Slide>
                    <Slide index={9}>"If your dog is too fat, you are not getting enough exercise." - Unknown</Slide>
                    <Slide index={10}>"Every puppy should have a boy." - Erma Bombeck</Slide>
                    <Slide index={11}>"His ears were often the first thing to catch my tears." - Elizabeth Barrett Browning</Slide>
                    <Slide index={12}>"The greatest fear dogs know is the fear that you will not come back when you go out the door without them." - Stanley Coren</Slide>
                    <Slide index={13}>"Love me, love my dog." - George Chapman</Slide>
                    <Slide index={14}>"Money will buy you a pretty good dog, but it won't buy the wag of is tail." - Unknown</Slide>
                    <Slide index={15}>"The one absolutely unselfish friend that man can have in this selfish world, the one that never deserts him, the one that never proves ungrateful or treacherous, is his dog." - George Graham Vest</Slide>
                    <Slide index={16}>"He is your friend, your partner, your defender, your dog. You are his life, his love, his leader. He will be yours, faithful and true, to the last beat of his heart. You owe it to him to be worthy of such devotion." - Unknown</Slide>
                    <Slide index={17}>"The bond with a true dog is as lasting as the ties of this earth will ever be." - Konrad Lorenz</Slide>       <Slide index={18}>"One reason a dog can be such a comfort when you're feeling blue is that he doesn't try to find out why." - Unknown</Slide>
                    <Slide index={19}>"Dogs' lives are too short. Their only fault, really." - Agnes Sligh Turnbull</Slide>
                    <Slide index={20}>"A dog is not 'almost human' and I know of no greater insult to the canine race than to describe it as such." - John Holmes</Slide>
                    <Slide index={21}>"The average dog is a nicer person than the average person." - Andy Rooney</Slide>                          <Slide index={22}>"If you think dogs can't count, try putting three dog treats in your pocket and then giving your Portuguese Water Dog only two of them." - Unknown</Slide>                  <Slide index={23}>"Dogs laugh, but they laugh with their tails." - Max Eastman</Slide>
                    <Slide index={24}>"The Portuguese Water Dog is defined by his job" - CKC</Slide> <Slide index={25}>"He has a hard, penetrating and attentive expression, splendid sight and a fair nose." - CKC</Slide>
                    <Slide index={26}>"From his impressive head with piercing gaze through a rugged body to his powerful tail carried up in a ring with its characteristic flowing flag, he presents an indelible impression of strength, spirit and soundness" - CKC</Slide>
                    <Slide index={27}>"An animal of spirited disposition, he is self-willed, brave and very resistant to fatigue" - CKC</Slide>
                    <Slide index={28}>"Exceptionally intelligent and a loyal companion, he does his job with facility and obvious pleasure" - CKC</Slide>
                    <Slide index={29}>"He displays a proud carriage, and a light happy attitude." - CKC</Slide>
                    <Slide index={30}>"If you pick up a starving dog and make him prosperous, he will not bite you. This is the principle difference between a dog and a man." - Mark Twain</Slide>
                    <Slide index={31}>"Every dog must have his day." - Jonathan Swift</Slide>
                    <Slide index={32}>"The joy, the solace, and aid of man..." - George Crabbe</Slide>
                    <Slide index={33}>"The rich man's guardian and the poor man's friend, The only creature faithful to the end." - George Crabbe</Slide>
                    <Slide index={34}>"If a dog will not come to you after he has looked you in the face, you ought to go home and examine your conscience." - Woodrow Wilson</Slide>
                    <Slide index={35}>"They are better than human beings, because they know but do not tell." - Emily Dickinson</Slide>
                    <Slide index={36}>"Beauty without Vanity, Strength without Insolance, Courage without Ferocity, and all the virtues of man without his vices." - John Cam Hobhouse</Slide>
                    <Slide index={37}>"A dog can express more with his eyes in minutes than his owner can express with his tongue in hours." - Unknown</Slide>
                    <Slide index={38}>"Make a special point everyday, of teaching your dog how to sit and stay. He will share your laughter and your tears, and always be faithful through the years." - Unknown</Slide>
                    <Slide index={39}>"Never be mean, Don't ever be cruel. He'll always be happy to play the fool. Remember he loves you, with all of his might. So stroke and caress him every night." - Unknown</Slide>
                    <Slide index={40}>"A watchdog is a dog kept to guard your home, usually by sleeping where a burglar would awaken the household by falling over him!" - Unknown</Slide>
                    <Slide index={41}>"When you tire of puppies ... you tire of life!" - Unknown</Slide>
                    <Slide index={42}>"His name is not the wild dog anymore, but the first friend, Because he will be our friend for always and always and always." - Rudyard Kipling</Slide>
                    <Slide index={43}>"Here, gentlemen, a dog teaches us a lesson in humanity." - Napoleon Bonaparte</Slide>
                    <Slide index={44}>"A dog owns nothing, yet is seldom dissatisfied."  - Irish Proverb</Slide>
                    <Slide index={45}>"You think dogs will not be in heaven?  I tell you, they will be there long before any of us." - Robert Louis Stevenson</Slide>
                    <Slide index={46}>"A dog is better than I am, for he has love and does not judge." - Saint Xanthais</Slide>
                    <Slide index={47}>"To err is human, to forgive, canine." - Unknown</Slide>
                    <Slide index={48}>"The greatest love is a mother's, Then comes a dog's..." - Polish Proverb</Slide>
                    <Slide index={49}>"The dog has the soul of a philosopher." - Plato</Slide>
                    <Slide index={50}>"I am a breeder, and there will be more dogs at my funeral than people. I hope!" - Joanna Kimball</Slide>
                </Slider>
            </CarouselProvider>
        </div>
    )
}

export default Quotes;
