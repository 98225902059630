import React from 'react';
import { NavLink } from "react-router-dom";
import logo from './assets/images/luna-logo2.svg';

const Header = () => {
    return (
        <>
            <div className="contact-container">
                <div className="contact content inline-flex">
                    <div className="names inline-flex">
                        <span className="name">Carolynne Issa</span>
                        <span className="name">Joanne Skalos</span>
                    </div>
                    <div className="contacts inline-flex">
                        <span className="icon-ref phone">519-590-4619</span>
                        <span className="icon-ref email">lunanovapwds@gmail.com</span>
                    </div>
                    <div className="social inline-flex">
                        <a href="https://www.youtube.com/user/LUNANOVAPWDS" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"></i></a>
                        <a href="https://www.instagram.com/lunanovapwds/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                    </div>
                </div>
            </div>
            <div className="header-container">
                <div className="header content">
                    <div className="logo">
                        <img src={logo} alt="Nova" />
                    </div>
                    <div className="navigation" id="mobile-nav">
                        <input type="checkbox" />
                        <p>menu</p>
                        <span></span>
                        <span></span>
                        <span></span>
                        <div className="header-nav">
                            <NavLink to="/">Home</NavLink>
                            <NavLink to="/dogs">The Dogs</NavLink>
                            <NavLink to="/adoptions">Adoptions</NavLink>
                            <NavLink to="/expect">What to Expect</NavLink>
                            <NavLink to="/about">About</NavLink>
                            <NavLink to="/contact">Contact</NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className="header-border"></div>
        </>
    )
}

export default Header;
