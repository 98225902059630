import React, { Component } from 'react';
import { Route, Routes, Outlet, Navigate } from "react-router-dom";
import './App.scss';
import Header from './Header';
import Footer from './Footer';
import Home from './Home';
import Dogs from './Dogs';
import Adoptions from './Adoptions';
import Expect from './Expect';
import About from './About';
import Contact from './Contact';
import Retired from './RetiredDogs';
import RainbowBridge from './RainbowBridge';


class App extends Component {
  render() {
    return (
      <div className="app">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dogs/*" element={<Dogs />} />
          <Route path="/retired-dogs" element={<Retired />} />
          <Route path="/rainbow-bridge" element={<RainbowBridge />} />
          <Route path="/adoptions" element={<Adoptions />} />
          <Route path="/expect" element={<Expect />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Outlet />
        <Footer />
      </div>
    );
  }
}

export default App;
