import React from 'react';
import DogDisplayPage from './DogDisplayPage';
import dakota1 from './assets/images/dakota/dakota1.jpg';
import dakota2 from './assets/images/dakota/dakota2.jpg';
import dakota3 from './assets/images/dakota/dakota3.jpg';
import dakota4 from './assets/images/dakota/dakota4.jpg';
import dakota5 from './assets/images/dakota/dakota5.jpg';
import dakota6 from './assets/images/dakota/dakota6.png';
import dakota7 from './assets/images/dakota/dakota7.jpg';
import dakota8 from './assets/images/dakota/dakota8.png';
import dakota9 from './assets/images/dakota/dakota9.jpg';
import dakota10 from './assets/images/dakota/dakota10.jpg';
import dakota11 from './assets/images/dakota/dakota11.jpg';
import dakota12 from './assets/images/dakota/dakota12.jpg';
import dakota13 from './assets/images/dakota/dakota13.jpg';
import dakota14 from './assets/images/dakota/dakota14.jpg';
import dakota15 from './assets/images/dakota/dakota15.jpg';
import dakota16 from './assets/images/dakota/dakota16.jpg';
import dakota17 from './assets/images/dakota/dakota17.jpg';
import dakota18 from './assets/images/dakota/dakota18.png';
import dakota19 from './assets/images/dakota/dakota19.jpg';
import dakota20 from './assets/images/dakota/dakota20.jpg';
import dakota21 from './assets/images/dakota/dakota21.jpg';
import dakota22 from './assets/images/dakota/dakota22.jpg';
import dakota23 from './assets/images/dakota/dakota23.jpg';
import dakota24 from './assets/images/dakota/dakota24.jpg';

const photos = [
    { src: dakota1, width: 157, height: 210 },
    { src: dakota2, width: 157, height: 210 },
    { src: dakota3, width: 157, height: 210 },
    { src: dakota4, width: 157, height: 210 },
    { src: dakota5, width: 157, height: 210 },
    { src: dakota6, width: 210, height: 210 },
    { src: dakota7, width: 157, height: 210 },
    { src: dakota8, width: 210, height: 157 },
    { src: dakota9, width: 157, height: 210 },
    { src: dakota10, width: 210, height: 210 },
    { src: dakota11, width: 157, height: 210 },
    { src: dakota12, width: 157, height: 210 },
    { src: dakota13, width: 157, height: 210 },
    { src: dakota14, width: 210, height: 210 },
    { src: dakota15, width: 157, height: 210 },
    { src: dakota16, width: 210, height: 210 },
    { src: dakota17, width: 157, height: 210 },
    { src: dakota18, width: 1, height: 2 },
    { src: dakota19, width: 157, height: 210 },
    { src: dakota20, width: 157, height: 210 },
    { src: dakota21, width: 157, height: 210 },
    { src: dakota22, width: 157, height: 210 },
    { src: dakota23, width: 210, height: 157 },
    { src: dakota24, width: 157, height: 210 },
];

const Dakota = () => {
    return (
        <DogDisplayPage photos={photos}>
            <h1 className="female">
                <i className="far fa-venus"></i>Dakota
            </h1>
            <table className="dog-chart">
                <tbody>
                    <tr>
                        <td><span className="title">Name</span>LUNANOVA'S DANCES WITH WOLVES AT SILVERSPRING</td>
                        <td><span className="title">Owner</span>Carolynne Issa<br />JOANNE SKALOS</td>
                        <td><span className="title">CKC Registration</span>#HE3994002</td>
                    </tr>
                    <tr>
                        <td><span className="title">Sire</span>AM CH / MBIS MBPIS BPISS CAN GCH NORTHSKY DARKHORSE AT LUNANOVA JWD AWD WWD</td>
                        <td><span className="title">Dam</span>CH LUNANOVA LET THE STORM RAGE ON</td>
                    </tr>
                    <tr>
                        <td><span className="title">Coat Type</span>CURLY</td>
                        <td><span className="title">Coat Colour</span>BLACK</td>
                    </tr>
                    <tr>
                        <td><span className="title">CERF</span>normal</td>
                        <td><span className="title">CDDY/IVVD</span>normal</td>
                        <td><span className="title">CDPA</span>normal</td>
                        <td><span className="title">EOPRA</span>normal</td>
                    </tr>
                    <tr>
                        <td><span className="title">GM-1</span>normal</td>
                        <td><span className="title">Improper Coat</span>normal</td>
                        <td><span className="title">JDCM</span>normal</td>
                        <td><span className="title">MICROPHTHALMIA SYNDROME</span>normal</td>
                    </tr>
                    <tr>
                        <td><span className="title">OFA Hips</span>EXCELLENT (ELBOWS NORMAL)</td>
                        <td><span className="title">PRA</span>normal</td>
                    </tr>
                </tbody>
            </table>
        </DogDisplayPage>
    );
}

export default Dakota;
