import React from 'react';
import DogDisplayPage from './DogDisplayPage';
import swift1 from './assets/images/swift/swift1.jpg';
import swift2 from './assets/images/swift/swift2.jpg';
import swift3 from './assets/images/swift/swift3.jpg';
import swift4 from './assets/images/swift/swift4.jpg';
import swift5 from './assets/images/swift/swift5.jpg';
import swift6 from './assets/images/swift/swift6.jpg';
import swift7 from './assets/images/swift/swift7.jpg';
import swift8 from './assets/images/swift/swift8.jpg';
import swift9 from './assets/images/swift/swift9.jpg';
import swift10 from './assets/images/swift/swift10.jpg';
import swift11 from './assets/images/swift/swift11.jpg';
import swift12 from './assets/images/swift/swift12.jpg';

const photos = [
    { src: swift1, width: 210, height: 210 },
    { src: swift2, width: 157, height: 210 },
    { src: swift3, width: 210, height: 157 },
    { src: swift4, width: 210, height: 157 },
    { src: swift5, width: 157, height: 210 },
    { src: swift6, width: 157, height: 210 },
    { src: swift7, width: 157, height: 210 },
    { src: swift8, width: 157, height: 210 },
    { src: swift9, width: 210, height: 157 },
    { src: swift10, width: 157, height: 210 },
    { src: swift11, width: 157, height: 210 },
    { src: swift12, width: 157, height: 210 }
];

const Swift = () => {
    return (
        <DogDisplayPage photos={photos}>
            <h1 className="female">
                <i className="far fa-venus"></i>Swift
            </h1>
            <table className="dog-chart">
                <tbody>
                    <tr>
                        <td><span className="title">Name</span>GCH CLAIRCREEK SHAKE IT OFF</td>
                        <td><span className="title">Owner</span>CAROLYNNE ISSA / DONNA GOTTDENKER</td>
                        <td><span className="title">CKC Registration</span>#GS3968983</td>
                    </tr>
                    <tr>
                        <td><span className="title">Sire</span>CH CLAIRCREEK REFLETS DE PISSARRO</td>
                        <td><span className="title">Dam</span>CH EUREKA TIMBEROAK MEDUSA</td>
                    </tr>
                    <tr>
                        <td><span className="title">Coat Type</span>WAVY</td>
                        <td><span className="title">Coat Colour</span>BLACK</td>
                    </tr>
                    <tr>
                        <td><span className="title">CERF</span>NORMAL</td>
                        <td><span className="title">CDDY/IVVD</span>normal</td>
                        <td><span className="title">CDPA</span>normal</td>
                        <td><span className="title">EOPRA</span>NORMAL</td>
                    </tr>
                    <tr>
                        <td><span className="title">GM-1</span>normal</td>
                        <td><span className="title">Improper Coat</span>normal</td>
                        <td><span className="title">JDCM</span>normal</td>
                        <td><span className="title">MICROPHTHALMIA SYNDROME</span>normal</td>
                    </tr>
                    <tr>
                        <td><span className="title">OFA Hips</span>GOOD (ELBOWS NORMAL)</td>
                        <td><span className="title">PRA</span>normal</td>
                    </tr>
                </tbody>
            </table>
        </DogDisplayPage>
    );
}
export default Swift;
