import React from 'react';
import DogDisplayPage from './DogDisplayPage';
import archie1 from './assets/images/archie/archie1.jpg';
import archie2 from './assets/images/archie/archie2.jpg';
import archie3 from './assets/images/archie/archie3.jpg';
import archie4 from './assets/images/archie/archie4.jpg';
import archie5 from './assets/images/archie/archie5.jpg';
import archie6 from './assets/images/archie/archie6.jpg';
import archie7 from './assets/images/archie/archie7.jpg';
import archie8 from './assets/images/archie/archie8.jpg';
import archie9 from './assets/images/archie/archie9.jpg';
import archie10 from './assets/images/archie/archie10.jpg';
import archie11 from './assets/images/archie/archie11.jpg';
import archie12 from './assets/images/archie/archie12.jpg';
import archie13 from './assets/images/archie/archie13.jpg';
import archie14 from './assets/images/archie/archie14.jpg';
import archie15 from './assets/images/archie/archie15.jpg';
import archie16 from './assets/images/archie/archie16.jpg';
import archie17 from './assets/images/archie/archie17.jpg';
import archie18 from './assets/images/archie/archie18.jpg';
import archie19 from './assets/images/archie/archie19.jpg';
import archie20 from './assets/images/archie/archie20.jpg';

const photos = [
    { src: archie1, width: 210, height: 210 },
    { src: archie2, width: 210, height: 210 },
    { src: archie3, width: 157, height: 210 },
    { src: archie4, width: 157, height: 210 },
    { src: archie5, width: 1, height: 2 },
    { src: archie6, width: 157, height: 210 },
    { src: archie7, width: 157, height: 210 },
    { src: archie8, width: 210, height: 210 },
    { src: archie9, width: 210, height: 210 },
    { src: archie10, width: 157, height: 210 },
    { src: archie11, width: 210, height: 157 },
    { src: archie12, width: 210, height: 157 },
    { src: archie13, width: 157, height: 210 },
    { src: archie14, width: 157, height: 210 },
    { src: archie15, width: 157, height: 210 },
    { src: archie16, width: 157, height: 210 },
    { src: archie17, width: 157, height: 210 },
    { src: archie18, width: 210, height: 157 },
    { src: archie19, width: 157, height: 210 },
    { src: archie20, width: 210, height: 210 },
];

const Archie = () => {
    return (
        <DogDisplayPage photos={photos}>
            <h1 className="male">
                <i className="far fa-mars"></i>Archie
                <span className="stud">Available for Stud service to approved Bitches</span>
            </h1>
            <table className="dog-chart">
                <tbody>
                    <tr>
                        <td><span className="title">Name</span>LUNANOVA'S SIR ARCHIE</td>
                        <td><span className="title">Owner</span>CAROLYNNE ISSA / JOANNE SKALOS</td>
                        <td width="100px"><span className="title">CKC Registration</span>#JE4073671</td>
                    </tr>
                    <tr>
                        <td><span className="title">Sire</span>CH LUNANOVA'S CHILKO FREESTONE</td>
                        <td><span className="title">Dam</span>CH SKIPNSTONES GODDESS AT LUNANOVA JWD</td>
                    </tr>
                    <tr>
                        <td><span className="title">Coat Type</span>CURLY</td>
                        <td><span className="title">Coat Colour</span>BLACK WITH WHITE MARKINGS</td>
                    </tr>
                    <tr>
                        <td><span className="title">CERF</span>NORMAL</td>
                        <td><span className="title">CDDY/IVVD</span>normal</td>
                        <td><span className="title">CDPA</span>normal</td>
                        <td><span className="title">EOPRA</span>normal</td>
                    </tr>
                    <tr>
                        <td><span className="title">GM-1</span>normal</td>
                        <td><span className="title">IMPROPER COAT</span>normal</td>
                        <td><span className="title">JDCM</span>normal</td>
                        <td><span className="title">MICROPHTHALMIA SYNDROME</span>normal</td>
                    </tr>
                    <tr>
                        <td><span className="title">OFA Hips</span>EXCELLENT (ELBOWS NORMAL)</td>
                        <td><span className="title">PRA</span>normal</td>
                    </tr>
                </tbody>
            </table>
        </DogDisplayPage>
    )

}

export default Archie;
