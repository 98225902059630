import React from 'react';
import DogDisplayPage from './DogDisplayPage';
import dahlia1 from './assets/images/dahlia/dahlia1.jpg';
import dahlia2 from './assets/images/dahlia/dahlia2.jpg';
import dahlia3 from './assets/images/dahlia/dahlia3.jpg';
import dahlia4 from './assets/images/dahlia/dahlia4.jpg';
import dahlia5 from './assets/images/dahlia/dahlia5.jpg';
import dahlia6 from './assets/images/dahlia/dahlia6.jpg';
import dahlia7 from './assets/images/dahlia/dahlia7.jpg';
import dahlia8 from './assets/images/dahlia/dahlia8.jpg';
import dahlia9 from './assets/images/dahlia/dahlia9.jpg';
import dahlia10 from './assets/images/dahlia/dahlia10.jpg';
import dahlia11 from './assets/images/dahlia/dahlia11.jpg';
import dahlia12 from './assets/images/dahlia/dahlia12.jpg';
import dahlia13 from './assets/images/dahlia/dahlia13.jpg';
import dahlia14 from './assets/images/dahlia/dahlia14.jpg';
import dahlia15 from './assets/images/dahlia/dahlia15.jpg';
import dahlia16 from './assets/images/dahlia/dahlia16.jpg';

const photos = [
    { src: dahlia1, width: 210, height: 157 },
    { src: dahlia2, width: 210, height: 157 },
    { src: dahlia3, width: 210, height: 157 },
    { src: dahlia4, width: 157, height: 210 },
    { src: dahlia5, width: 210, height: 157 },
    { src: dahlia6, width: 210, height: 210 },
    { src: dahlia7, width: 157, height: 210 },
    { src: dahlia8, width: 210, height: 157 },
    { src: dahlia9, width: 210, height: 157 },
    { src: dahlia10, width: 210, height: 157 },
    { src: dahlia11, width: 210, height: 157 },
    { src: dahlia12, width: 210, height: 157 },
    { src: dahlia13, width: 210, height: 157 },
    { src: dahlia14, width: 210, height: 157 },
    { src: dahlia15, width: 210, height: 157 },
    { src: dahlia16, width: 210, height: 157 },
];

const Dahlia = () => {
    return (
        <DogDisplayPage photos={photos}>
            <h1 className="female">
                <i className="far fa-venus"></i>Dahlia (retired)
            </h1>
            <table className="dog-chart">
                <tbody>
                    <tr>
                        <td><span className="title">Name</span>GCH BBPIS LUNANOVAS DAHLIA FILHA DA FLOR CGN WWD</td>
                        <td><span className="title">Owner</span>Carolynne Issa</td>
                        <td width="100px"><span className="title">CKC Registration</span>#BA544111</td>
                    </tr>
                    <tr>
                        <td><span className="title">Sire</span>AM/CAN CH TSAVO'S MACKINAC LIGHT OF DELSUR</td>
                        <td><span className="title">Dam</span>CH LUNANOVA MAIAS FLOR DA LAVANDA AWD CGN</td>
                    </tr>
                    <tr>
                        <td><span className="title">Coat Type</span>Wavy</td>
                        <td><span className="title">Coat Colour</span>BROWN WITH WHITE MARKINGS</td>
                    </tr>
                    <tr>
                        <td><span className="title">OFA Hips</span>EXCELLENT</td>
                        <td><span className="title">CERF</span>NORMAL</td>
                        <td><span className="title">Optigen PRA</span>normal</td>
                    </tr>
                    <tr>
                        <td><span className="title">Optigen IC</span>normal</td>
                        <td><span className="title">GM-1</span>normal</td>
                        <td><span className="title">JDCM</span>normal</td>
                    </tr>
                </tbody>
            </table>
        </DogDisplayPage>
    )

}
export default Dahlia;
