import React from 'react';
import DogDisplayPage from './DogDisplayPage';
import echo1 from './assets/images/echo/echo1.jpg';
import echo2 from './assets/images/echo/echo2.jpg';
import echo3 from './assets/images/echo/echo3.jpg';
import echo4 from './assets/images/echo/echo4.jpg';
import echo5 from './assets/images/echo/echo5.jpg';
import echo6 from './assets/images/echo/echo6.jpg';
import echo7 from './assets/images/echo/echo7.jpg';
import echo8 from './assets/images/echo/echo8.jpg';
import echo9 from './assets/images/echo/echo9.jpg';
import echo10 from './assets/images/echo/echo10.jpg';
import echo11 from './assets/images/echo/echo11.jpg';
import echo12 from './assets/images/echo/echo12.jpg';

const photos = [
    { src: echo1, width: 157, height: 210 },
    { src: echo2, width: 157, height: 210 },
    { src: echo3, width: 157, height: 210 },
    { src: echo4, width: 157, height: 210 },
    { src: echo5, width: 157, height: 210 },
    { src: echo6, width: 210, height: 210 },
    { src: echo7, width: 140, height: 210 },
    { src: echo8, width: 210, height: 210 },
    { src: echo9, width: 157, height: 210 },
    { src: echo10, width: 157, height: 210 },
    { src: echo11, width: 210, height: 157 },
    { src: echo12, width: 210, height: 157 }
];

const Echo = () => {
    return (
        <DogDisplayPage photos={photos}>
            <h1 className="female">
                <i className="far fa-venus"></i>Echo
            </h1>
            <table className="dog-chart">
                <tbody>
                    <tr>
                        <td><span className="title">Name</span>LUNANOVA ZELDA'S LEGEND REPEATS</td>
                        <td><span className="title">Owner</span>CAROLYNNE ISSA / JOANNE SKALOS / SARA HUNTER</td>
                        <td><span className="title">CKC Registration</span>#GQ3969414</td>
                    </tr>
                    <tr>
                        <td><span className="title">Sire</span>CH CASCADES SKIPNSTONES ACROSS THE RIO</td>
                        <td><span className="title">Dam</span>CH LUNANOVA'S LEGEND OF ZELDA</td>
                    </tr>
                    <tr>
                        <td><span className="title">Coat Type</span>WAVY</td>
                        <td><span className="title">Coat Colour</span>BLACK WITH WHITE MARKINGS</td>
                    </tr>
                    <tr>
                        <td><span className="title">CERF</span>NORMAL</td>
                        <td><span className="title">CDDY/IVVD</span>normal</td>
                        <td><span className="title">CDPA</span>normal</td>
                        <td><span className="title">EOPRA</span>NORMAL</td>
                    </tr>
                    <tr>
                        <td><span className="title">GM-1</span>normal</td>
                        <td><span className="title">Improper coat</span>normal</td>
                        <td><span className="title">JDCM</span>normal</td>
                        <td><span className="title">MICROPHTHALMIA SYNDROME</span>normal</td>
                    </tr>
                    <tr>
                        <td><span className="title">OFA Hips</span>FAIR (ELBOWS NORMAL)</td>
                        <td><span className="title">PRA</span>normal</td>
                    </tr>
                </tbody>
            </table>
        </DogDisplayPage>
    );
}
export default Echo;
