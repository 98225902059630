import React from 'react';
import DogDisplayPage from './DogDisplayPage';
import perry1 from './assets/images/perry/perry1.jpg';
import perry2 from './assets/images/perry/perry2.jpg';
import perry3 from './assets/images/perry/perry3.jpg';
import perry4 from './assets/images/perry/perry4.jpg';
import perry5 from './assets/images/perry/perry5.jpg';
import perry6 from './assets/images/perry/perry6.jpg';
import perry7 from './assets/images/perry/perry7.jpg';
import perry8 from './assets/images/perry/perry8.jpg';
import perry9 from './assets/images/perry/perry9.jpg';
import perry10 from './assets/images/perry/perry10.jpg';
import perry11 from './assets/images/perry/perry11.jpg';
import perry12 from './assets/images/perry/perry12.jpg';
import perry13 from './assets/images/perry/perry13.jpg';
import perry14 from './assets/images/perry/perry14.jpg';
import perryaward1 from './assets/images/perry/perry-award1.jpg';
import perryaward2 from './assets/images/perry/perry-award2.jpg';

const photos = [
    { src: perry1, width: 210, height: 157 },
    { src: perry2, width: 210, height: 157 },
    { src: perry3, width: 210, height: 210 },
    { src: perry4, width: 210, height: 157 },
    { src: perry5, width: 157, height: 210 },
    { src: perry6, width: 1, height: 1.75 },
    { src: perry7, width: 210, height: 157 },
    { src: perry8, width: 210, height: 157 },
    { src: perry9, width: 210, height: 157 },
    { src: perry10, width: 210, height: 157 },
    { src: perry11, width: 210, height: 157 },
    { src: perry12, width: 157, height: 210 },
    { src: perry13, width: 210, height: 157 },
    { src: perry14, width: 210, height: 157 },
    { src: perryaward1, width: 210, height: 157 },
    { src: perryaward2, width: 210, height: 157 }
];

const Perry = () => {
    return (
        <DogDisplayPage photos={photos}>
            <h1 className="male">
                <i className="far fa-mars"></i>Perry
                <span className="stud">Available for Stud service to approved Bitches</span>
            </h1>
            <table className="dog-chart">
                <tbody>
                    <tr>
                        <td><span className="title">Name</span>AM CH / MBIS MBPIS BPISS CAN GCH NORTHSKY DARKHORSE AT LUNANOVA JWD AWD WWD</td>
                        <td><span className="title">Owner</span>CAROLYNNE ISSA</td>
                        <td><span className="title">CKC Registration</span>#CJ622162</td>
                    </tr>
                    <tr>
                        <td>#1 PWD IN 2016</td>
                        <td><span className="title">Sire</span>MBIS BISS AM CH/CAN GCH CLAIRCREEK LUSITANO OCEANO ATLANTICO</td>
                        <td><span className="title">Dam</span>CH KEEVA BAY'S RUMOR HAS IT</td>
                    </tr>
                    <tr>
                        <td><span className="title">Coat Type</span>CURLY</td>
                        <td><span className="title">Coat Colour</span>BLACK</td>
                    </tr>
                </tbody>
            </table>
            <table className="dog-chart">
                <tbody>
                    <tr>
                        <td><span className="title">CERF</span>NORMAL</td>
                        <td><span className="title">CDDY/IVVD</span>normal</td>
                        <td><span className="title">CDPA</span>normal</td>
                        <td><span className="title">GM-1</span>normal</td>
                    </tr>
                    <tr>
                        <td><span className="title">JDCM</span>normal</td>
                        <td><span className="title">MICROPHTHALMIA SYNDROME</span>normal</td>
                        <td><span className="title">OFA Hips</span>Excellent (Elbows normal)</td>
                        <td><span className="title">OPTIGEN EOPRA</span>NORMAL</td>
                    </tr>
                    <tr>
                        <td><span className="title">Optigen IC</span>normal</td>
                        <td><span className="title">Optigen PRA</span>normal</td>
                    </tr>
                </tbody>
            </table>
        </DogDisplayPage>
    )
}

export default Perry;
