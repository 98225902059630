import React, { useState } from 'react';

import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import candyWeek1 from './assets/images/expect/candy/week6/A1.jpg';
import candyWeek2 from './assets/images/expect/candy/week6/B1.jpg';
import candyWeek3 from './assets/images/expect/candy/week6/C1.jpg';
import candyWeek4 from './assets/images/expect/candy/week6/D1.jpg';
import candyWeek5 from './assets/images/expect/candy/week6/E1.jpg';
import candyWeek6 from './assets/images/expect/candy/week6/F1.jpg';
import candyWeek7 from './assets/images/expect/candy/week6/G1.jpg';

const photos1 = [
    { src: candyWeek1, width: 157, height: 210 }
];

const photos2 = [
    { src: candyWeek1 },
    { src: candyWeek2 },
    { src: candyWeek3 },
    { src: candyWeek4 },
    { src: candyWeek5 },
    { src: candyWeek6 },
    { src: candyWeek7 }
];

const Candyd1 = () => {
    const [index, setIndex] = useState(-1);
    return (
        <div className="litter-gallery">
            <PhotoAlbum photos={photos1} layout="rows" targetRowHeight={150} onClick={({ index }) => setIndex(index)} />
            <Lightbox
                slides={photos2}
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
            />
        </div>
    )

}

export default Candyd1;
