import React from 'react';
import DogDisplayPage from './DogDisplayPage';
import scarlett1 from './assets/images/scarlett/1.jpg';
import scarlett2 from './assets/images/scarlett/2.jpg';
import scarlett3 from './assets/images/scarlett/3.jpg';
import scarlett4 from './assets/images/scarlett/4.jpg';
import scarlett5 from './assets/images/scarlett/5.jpg';
import scarlett6 from './assets/images/scarlett/6.jpg';
import scarlett7 from './assets/images/scarlett/7.jpg';
import scarlett8 from './assets/images/scarlett/8.jpg';
import scarlett9 from './assets/images/scarlett/9.jpg';
import scarlett10 from './assets/images/scarlett/10.jpg';
import scarlett11 from './assets/images/scarlett/11.jpg';
import scarlett12 from './assets/images/scarlett/12.jpg';
import scarlett13 from './assets/images/scarlett/13.jpg';
import scarlett14 from './assets/images/scarlett/14.jpg';
import scarlett15 from './assets/images/scarlett/15.jpg';
import scarlett16 from './assets/images/scarlett/16.jpg';
import scarlett17 from './assets/images/scarlett/17.jpg';
import scarlett18 from './assets/images/scarlett/18.jpg';
import scarlett19 from './assets/images/scarlett/19.jpg';
import scarlett20 from './assets/images/scarlett/20.jpg';
import scarlett21 from './assets/images/scarlett/21.jpg';
import scarlett22 from './assets/images/scarlett/22.jpg';
import scarlett23 from './assets/images/scarlett/23.jpg';
import scarlett24 from './assets/images/scarlett/24.jpg';
import scarlett25 from './assets/images/scarlett/25.jpg';

const photos = [
  { src: scarlett1, width: 200, height: 125 },
  { src: scarlett2, width: 200, height: 125 },
  { src: scarlett3, width: 200, height: 200 },
  { src: scarlett4, width: 100, height: 200 },
  { src: scarlett5, width: 200, height: 80 },
  { src: scarlett6, width: 200, height: 150 },
  { src: scarlett7, width: 100, height: 200 },
  { src: scarlett8, width: 200, height: 100 },
  { src: scarlett9, width: 100, height: 200 },
  { src: scarlett10, width: 150, height: 200 },
  { src: scarlett11, width: 150, height: 200 },
  { src: scarlett12, width: 150, height: 200 },
  { src: scarlett13, width: 100, height: 200 },
  { src: scarlett14, width: 200, height: 200 },
  { src: scarlett15, width: 150, height: 200 },
  { src: scarlett16, width: 200, height: 100 },
  { src: scarlett17, width: 200, height: 150 },
  { src: scarlett18, width: 150, height: 200 },
  { src: scarlett19, width: 200, height: 150 },
  { src: scarlett20, width: 200, height: 150 },
  { src: scarlett21, width: 100, height: 200 },
  { src: scarlett22, width: 200, height: 200 },
  { src: scarlett23, width: 200, height: 200 },
  { src: scarlett24, width: 200, height: 150 },
  { src: scarlett25, width: 150, height: 200 },
];

const Scarlett = () => {
  return (
    <DogDisplayPage photos={photos}>
      <h1 className="female">
        <i className="far fa-venus"></i>Scarlett
      </h1>
      <table className="dog-chart">
        <tbody>
          <tr>
            <td><span className="title">Name</span>LUNANOVA'S FRANKLY MY DEAR, I DON'T GIVE A DAMN</td>
            <td><span className="title">Owner</span>CAROLYNNE ISSA / JOANNE SKALOS</td>
            <td width="100px"><span className="title">CKC Registration</span>#JQ4124251</td>
          </tr>
          <tr>
            <td><span className="title">Sire</span>CH LUNANOVA'S CHILKO FREESTONE</td>
            <td><span className="title">Dam</span>CH LUNANOVA LET THE STORM RAGE ON</td>
          </tr>
          <tr>
            <td><span className="title">Coat Type</span>Wavy</td>
            <td><span className="title">Coat Colour</span>BLACK</td>
          </tr>
          <tr>
            <td><span className="title">CERF</span>NORMAL</td>
            <td><span className="title">CDDY/IVVD</span>normal</td>
            <td><span className="title">CDPA</span>normal</td>
            <td><span className="title">EOPRA</span>normal</td>
          </tr>
          <tr>
            <td><span className="title">GM-1</span>normal</td>
            <td><span className="title">Improper Coat</span>normal</td>
            <td><span className="title">JDCM</span>normal</td>
            <td><span className="title">MICROPHTHALMIA SYNDROME</span>normal</td>
          </tr>
          <tr>
            <td><span className="title">OFA Hips</span>GOOD (ELBOWS NORMAL)</td>
            <td><span className="title">PRA</span>normal</td>
          </tr>
        </tbody>
      </table>
    </DogDisplayPage>
  )

}
export default Scarlett;
