import React from 'react';
import DogDisplayPage from './DogDisplayPage';
import chilko1 from './assets/images/chilko/chilko1.jpg';
import chilko2 from './assets/images/chilko/chilko2.jpg';
import chilko3 from './assets/images/chilko/chilko3.jpg';
import chilko4 from './assets/images/chilko/chilko4.jpg';
import chilko5 from './assets/images/chilko/chilko5.jpg';
import chilko6 from './assets/images/chilko/chilko6.jpg';
import chilko7 from './assets/images/chilko/chilko7.jpg';
import chilko8 from './assets/images/chilko/chilko8.jpg';
import chilko9 from './assets/images/chilko/chilko9.jpg';
import chilko10 from './assets/images/chilko/chilko10.jpg';

const photos = [
    { src: chilko1, width: 157, height: 210 },
    { src: chilko2, width: 210, height: 157 },
    { src: chilko3, width: 210, height: 157 },
    { src: chilko4, width: 157, height: 210 },
    { src: chilko5, width: 157, height: 210 },
    { src: chilko6, width: 210, height: 157 },
    { src: chilko7, width: 157, height: 210 },
    { src: chilko8, width: 157, height: 210 },
    { src: chilko9, width: 210, height: 157 },
    { src: chilko10, width: 210, height: 157 }
];

const Chilko = () => {
    return (
        <DogDisplayPage photos={photos}>
            <h1 className="male">
                <i className="far fa-mars"></i>Chilko
                <span className="stud">Available for Stud service to approved Bitches</span>
            </h1>
            <table className="dog-chart">
                <tbody>
                    <tr>
                        <td><span className="title">Name</span>CH LUNANOVA'S CHILKO FREESTONE</td>
                        <td><span className="title">Owner</span>Carolynne Issa<br />Sara Hunter</td>
                        <td><span className="title">CKC Registration</span>#CN617014</td>
                    </tr>
                    <tr>
                        <td><span className="title">Sire</span>AM/CAN CH TSAVO'S MACKINAC LIGHT OF DELSUR</td>
                        <td><span className="title">Dam</span>CH LUNANOVA MAIAS FLOR DA LAVANDA AWD CGN</td>
                    </tr>
                    <tr>
                        <td><span className="title">Coat Type</span>CURLY</td>
                        <td><span className="title">Coat Colour</span>BLACK WITH WHITE MARKINGS</td>
                    </tr>
                    <tr>
                        <td><span className="title">CERF</span>NORMAL</td>
                        <td><span className="title">CDDY/IVVD</span>normal</td>
                        <td><span className="title">CDPA</span>normal</td>
                        <td><span className="title">GM-1</span>normal</td>
                    </tr>
                    <tr>
                        <td><span className="title">JDCM</span>normal</td>
                        <td><span className="title">MICROPHTHALMIA SYNDROME</span>normal</td>
                        <td><span className="title">OFA Hips</span>EXCELLENT (elbows normal)</td>
                        <td><span className="title">OPTIGEN EOPRA</span>NORMAL</td>
                    </tr>
                    <tr>
                        <td><span className="title">Optigen PRA</span>normal</td>
                        <td><span className="title">Optigen IC</span>normal</td>
                    </tr>
                </tbody>
            </table>
        </DogDisplayPage>
    );
}

export default Chilko;
