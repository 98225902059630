import React, { useState } from 'react';

import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import scaryWeek1 from './assets/images/expect/scary/week3/A1.jpg';
import scaryWeek2 from './assets/images/expect/scary/week3/B1.jpg';
import scaryWeek3 from './assets/images/expect/scary/week3/C1.jpg';
import scaryWeek4 from './assets/images/expect/scary/week3/D1.jpg';
import scaryWeek5 from './assets/images/expect/scary/week3/E1.jpg';
import scaryWeek6 from './assets/images/expect/scary/week3/F1.jpg';
import scaryWeek7 from './assets/images/expect/scary/week3/H1.jpg';
import scaryWeek8 from './assets/images/expect/scary/week3/I1.jpg';

const photos1 = [
    { src: scaryWeek1, width: 157, height: 210 }
];

const photos2 = [
    { src: scaryWeek1 },
    { src: scaryWeek2 },
    { src: scaryWeek3 },
    { src: scaryWeek4 },
    { src: scaryWeek5 },
    { src: scaryWeek6 },
    { src: scaryWeek7 },
    { src: scaryWeek8 }
];

const Candyd1 = () => {
    const [index, setIndex] = useState(-1);
    return (
        <div className="litter-gallery">
            <PhotoAlbum photos={photos1} layout="rows" targetRowHeight={150} onClick={({ index }) => setIndex(index)} />
            <Lightbox
                slides={photos2}
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
            />
        </div>
    )

}

export default Candyd1;
