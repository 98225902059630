import React from 'react';
import DogDisplayPage from './DogDisplayPage';
import fleur1 from './assets/images/fleur/fleur1.jpeg';
import fleur2 from './assets/images/fleur/fleur2.jpeg';
import fleur3 from './assets/images/fleur/fleur3.jpeg';
import fleur4 from './assets/images/fleur/fleur4.jpeg';
import fleur5 from './assets/images/fleur/fleur5.jpeg';
import fleur6 from './assets/images/fleur/fleur6.jpeg';
import fleur7 from './assets/images/fleur/fleur7.jpeg';
import fleur8 from './assets/images/fleur/fleur8.jpeg';
import fleur9 from './assets/images/fleur/fleur9.jpeg';
import fleur10 from './assets/images/fleur/fleur10.jpg';
import fleur11 from './assets/images/fleur/fleur11.jpg';
import fleur12 from './assets/images/fleur/fleur12.jpg';

const photos = [
    { src: fleur1, width: 157, height: 210 },
    { src: fleur2, width: 157, height: 210 },
    { src: fleur3, width: 157, height: 210 },
    { src: fleur4, width: 157, height: 210 },
    { src: fleur5, width: 157, height: 210 },
    { src: fleur6, width: 157, height: 157 },
    { src: fleur7, width: 157, height: 210 },
    { src: fleur8, width: 157, height: 210 },
    { src: fleur9, width: 1, height: 2 },
    { src: fleur10, width: 210, height: 157 },
    { src: fleur11, width: 210, height: 157 },
    { src: fleur12, width: 210, height: 157 },
];

const Fleur = () => {
    return (
        <DogDisplayPage photos={photos}>
            <h1 className="female">
                <i className="far fa-venus"></i>Fleur
            </h1>
            <table className="dog-chart">
                <tbody>
                    <tr>
                        <td><span className="title">Name</span>LUNANOVA'S FLOWER OF THE HEART</td>
                        <td><span className="title">Owner</span>CAROLYNNE ISSA / DARRYL AND EMMA LAW</td>
                        <td><span className="title">CKC Registration</span>#EW735128</td>
                    </tr>
                    <tr>
                        <td><span className="title">Sire</span>AM CH / MBIS MBPIS BPISS CAN GCH NORTHSKY DARKHORSE AT LUNANOVA JWD AWD WWD</td>
                        <td><span className="title">Dam</span>GCH BBPIS LUNANOVAS DAHLIA FILHA DA FLOR CGN WWD</td>
                    </tr>
                    <tr>
                        <td><span className="title">Coat Type</span>WAVY</td>
                        <td><span className="title">Coat Colour</span>BLACK WITH WHITE MARKINGS</td>
                    </tr>
                    <tr>
                        <td><span className="title">CERF</span>NORMAL</td>
                        <td><span className="title">CDDY/IVVD</span>normal</td>
                        <td><span className="title">CDPA</span>normal</td>
                        <td><span className="title">GM-1</span>normal</td>
                    </tr>
                    <tr>
                        <td><span className="title">JDCM</span>normal</td>
                        <td><span className="title">MICROPHTHALMIA SYNDROME</span>normal</td>
                        <td><span className="title">OFA Hips</span>GOOD (ELBOWS NORMAL)</td>
                        <td><span className="title">OPTIGEN EOPRA</span>NORMAL</td>
                    </tr>
                    <tr>
                        <td><span className="title">Optigen IC</span>normal</td>
                        <td><span className="title">Optigen PRA</span>normal</td>
                    </tr>
                </tbody>
            </table>
        </DogDisplayPage>
    );
}

export default Fleur;
